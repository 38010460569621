import { IBundleSelectionData } from './bundle-option';

export interface IMappedSelectionsAttribute {
    attributeLabel: string;
    values: {[key: string]: IMappedSelectionsAttribute | string};
}

export interface IMappedSelectionsForSwatches {
    [key: string]: IMappedSelectionsAttribute;
}

export interface IMappedColorSwatchImages {
    [key: string]: { [key: string]: string };
}

const constructSizeAttributes = (
    selection: IBundleSelectionData,
    sizeValues: IMappedSelectionsAttribute,
): IMappedSelectionsAttribute => {
    const sizeAttributes: IMappedSelectionsAttribute = { ...sizeValues };
    if (!sizeAttributes.attributeLabel) {
        sizeAttributes.attributeLabel = 'size';
    }

    if (!sizeAttributes.values[selection.size]) {
        sizeAttributes.values[selection.size] = selection.selection_id;
    }

    return sizeAttributes;
};

const constructColorAttributes = (
    selection: IBundleSelectionData,
    mappedSelection: IMappedSelectionsAttribute,
): IMappedSelectionsAttribute => {
    const colorAttributes: IMappedSelectionsAttribute = { ...mappedSelection };
    if (!colorAttributes.attributeLabel) {
        colorAttributes.attributeLabel = 'color';
    }

    if (!colorAttributes.values[selection.color]) {
        colorAttributes.values[selection.color] = {
            attributeLabel: '',
            values: {},
        };
    }

    if (selection.size) {
        colorAttributes.values[selection.color] = constructSizeAttributes(
            selection,
            colorAttributes.values[selection.color] as IMappedSelectionsAttribute,
        );
    } else {
        colorAttributes.values[selection.color] = selection.selection_id;
    }

    return colorAttributes;
};

export const groupColorSwatchImages = (
    selections: Array<IBundleSelectionData>,
): IMappedColorSwatchImages => {
    const mappedSwatchImages: IMappedColorSwatchImages = {};

    selections.forEach((selection) => {
        if (mappedSwatchImages[selection.selection_title] &&
            mappedSwatchImages[selection.selection_title][selection.color]) {
            return;
        }

        if (!mappedSwatchImages[selection.selection_title]) {
            mappedSwatchImages[selection.selection_title] = {};
        }

        mappedSwatchImages[selection.selection_title][selection.color] =
            selection.color_swatch_image;
    });

    return mappedSwatchImages;
};

export const groupSelectionsForSwatches = (
    selections: Array<IBundleSelectionData>,
): IMappedSelectionsForSwatches => {
    const selectionsGroupedByProductName: {[key: string]: Array<IBundleSelectionData>} = {};

    selections.forEach((selection) => {
        if (!selectionsGroupedByProductName[selection.selection_title]) {
            selectionsGroupedByProductName[selection.selection_title] = [];
        }

        selectionsGroupedByProductName[selection.selection_title].push(selection);
    });

    const mappedSelections: IMappedSelectionsForSwatches = {};

    Object.keys(selectionsGroupedByProductName).forEach((productName) => {
        const productSelections = selectionsGroupedByProductName[productName];
        mappedSelections[productName] = {
            attributeLabel: '',
            values: {},
        };

        productSelections.forEach((selection) => {
            if (selection.color) {
                mappedSelections[productName] =
                    constructColorAttributes(selection, mappedSelections[productName]);
            } else {
                mappedSelections[productName] =
                    constructSizeAttributes(selection, mappedSelections[productName]);
            }
        });
    });

    return mappedSelections;
};
