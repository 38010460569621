import React from 'react';
import { ReviewsSnippet } from './snippet/snippet';
import styles from './style.css';

interface IRecipeProps {
    reviewData?: any;
}

export class ConsumerFactory extends React.Component<IRecipeProps> {
    render() {
        const { reviewData } = this.props;
        // eslint-disable-next-line prefer-destructuring
        const comp = reviewData.comp;
        // eslint-disable-next-line prefer-destructuring
        const productId = reviewData?.product?.product?.id || reviewData?.product?.product?.product?.id;
        return (
            <div
                ref={e => e}
                id={`reviews-${comp}`}
                className={`${styles.review}`}
            >
                <ReviewsSnippet productId={productId} productReviews={[]} />
            </div>
        );
    }
}
