import { connect } from '@silkpwa/redux';

export const connectProductReviews = connect({
    using: ['ecommerceReviews'],
    mapStateToProps: productReviews => (state, { productId }) => ({
        productReviews: productReviews.selectors.getProductReviews(state, productId),
    }),
    mapDispatchToProps: ecommerceReviews => dispatch => ({
        resetReviewAfterVote:
            productId => dispatch(
                ecommerceReviews.actions.resetReviewAfterVote(productId),
            ),
        fetchProductReviews:
            productId => dispatch(
                ecommerceReviews.actions.fetchProductReviews(productId),
            ),
    }),
});
