import React from 'react';
import { Router } from '@silkpwa/module/react-component/router';
import { Container } from 'ui/component/container';
import styles from './style.css';

/* We will show the recently viewed footer on all
pages except home */
export const RecentlyViewedFooter = () => (
    <Router
        homepage={null}
        notFound={
            <Container id="recently-viewed-footer" className={styles.recentlyViewed} />
        }
    />
);
