import React from 'react';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { usePortalInfo } from '@silkpwa/module/portal';
import { PortalData } from 'ui/component/portal/portal-data/portal-data';
import { useDesignConfig } from '@silkpwa/module/design-config';
import styles from './style.css';

export const Logo = () => {
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const portalInfo = usePortalInfo();
    const designConfig = useDesignConfig();
    const logoUrl = designConfig.logoUrl ?? '';
    const pageTitle = designConfig.pageTitle ?? '';

    return (
        <span data-test="logo">
            <div className={styles.logo}>
                <a
                    data-test="logo-link"
                    href={
                        isPunchoutInspectOperation ? window.location.href : '/'
                    }
                    className={styles.logo}
                >
                    <img
                        src={logoUrl}
                        alt={pageTitle}
                        width="296"
                        height="50"
                    />
                </a>
            </div>
            {portalInfo.isPortal
                ? (
                    <PortalData
                        area="headerLogo"
                        data={portalInfo.portal}
                        isCustomerLoggedIn={portalInfo.isCustomerLoggedIn}
                    />
                    )
                : '' }
        </span>
    );
};
