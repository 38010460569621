import React from 'react';
import isEqual from 'lodash/isEqual';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';

interface IRenderConfiguratorProps {
    data: IProductConfigData;
}

/**
 * Product configurator update state change object.
 * Variables from IProductConfigData which need to be tracked (and persisted) during PDP page
 * state changes should be added to the 'Pick' list below
 */
interface IShouldComponentUpdateState extends Pick<IProductConfigData, 'selections' |
'simpleProduct' |
'productImages' |
'product' |
'selectionsQty' |
'calculatedPrice' |
'calculatedOriginalPrice' |
'validation' |
'reviews' |
'dynamicSku'> {
    quantity: number|null; // we want to flatten quantity to single dimension, so we don't pick from IProductConfigData
}

export class RenderConfigurator extends React.Component<IRenderConfiguratorProps, any> {
    private currentState?: IShouldComponentUpdateState;

    shouldComponentUpdate(nextProps: Readonly<IRenderConfiguratorProps>) {
        const { data } = nextProps;

        const newState: IShouldComponentUpdateState = {
            selections: data.selections,
            quantity: data.quantity ? data.quantity.current : null,
            selectionsQty: data.selectionsQty, // bundle product
            calculatedPrice: data.calculatedPrice, // bundle product
            calculatedOriginalPrice: data.calculatedOriginalPrice, // bundle product
            dynamicSku: data.dynamicSku, // bundle product
            simpleProduct: data.simpleProduct, // simple product
            productImages: data.productImages, // simple product
            product: data.product, // simple product
            validation: data.validation, // configurable and bundle product
            reviews: data.reviews,
        };
        const oldState = this.currentState;
        this.currentState = newState;
        return !isEqual(newState, oldState);
    }

    render() {
        const { data, children } = this.props;
        const selectedOptionsData = window.sessionStorage.getItem('selectedOptions');
        const selectedOptions = typeof selectedOptionsData === 'string' ? JSON.parse(selectedOptionsData) : {};
        const selectedOption = selectedOptions[data.product.id];
        const visualSwatchesId = data?.attributes?.find(attr => attr.type === 'VisualSwatches')?.id;

        /**
         * Fix according to the new logic introduced in CWM2-7606:
         *  - Preselect color/size options for configurable products;
         *  - See `silkpwa-module/react-component/product-config/configurable-product/configurable-product.tsx`,
         *    method `handleSingleOptionSelect`.
         */
        if (selectedOption && (Object.keys(data.selections).length === 0 || !data.selections[visualSwatchesId])) {
            data.selections[visualSwatchesId] = parseInt(selectedOption, 10);
        }

        const render = children as React.FC<IProductConfigData>;

        return render(data);
    }
}
