import { inject } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { isPunchoutStoreMode } from '@silkpwa/module/store-mode';
import { Location } from 'history';
import { Cookie } from '@silkpwa/module/cookie/cookie';
import { SetCurrentStore } from '../set-current-store/set-current-store';
import {
    decodeReferer,
    matches,
} from './util';
import { redirectToMagento } from './redirect-to-magento';


@inject(
    'magentoAPI',
    'cookie',
    'SetCurrentStore',
)
export class CMSRouter {
    constructor(
        private magentoAPI: Http,
        private cookie: Cookie,
        private setCurrentStore: SetCurrentStore,
    ) { }

    getResourceInfo(location: Location) {
        setTimeout(() => this.redirectLoginPage(this.cookie), 2000);
        return new Promise((resolve: Function, reject: Function) => {
            /* TODO: API does not properly return types for these
            URLS */
            if (matches(resolve, location, [
                { path: '/', type: 'homepage' },
                { path: '/checkout/cart', type: 'cart' },
                { path: '/checkout', type: 'checkout' },
                { path: '/checkout/:step', type: 'checkout' },
                {
                    path: '/checkout/success/:order/',
                    type: 'success',
                },
                { path: '/customer/account/login', type: 'login' },
                {
                    path: '/customer/account/login/referer/:redirectUri',
                    type: 'login',
                    extract: (obj, params) => ({
                        ...obj,
                        params: {
                            redirectUri: decodeReferer(decodeURIComponent(params.redirectUri)),
                        },
                    }),
                },
                // {path: '/customer/account/create', type: 'register'},
                { path: '/config', type: 'configpage' },
                { path: '/catalogsearch/result', type: 'search' },
                {
                    path: '/catalog/product/view/id/:productId',
                    type: 'product',
                    extract: (obj, params) => ({
                        ...obj, resourceId: Number(params.productId),
                    }),
                },
            ])) return;

            /* only below should be necessary */

            const urlcode = btoa(`${location.pathname}`);
            const search = btoa(`${location.search}`);
            this.magentoAPI.get(`/app-check-url?urlcode=${urlcode}&search=${search}`).then(
                this.handleResponse(location, resolve),
                err => reject(err),
            );
        });
    }

    handleResponse(location: Location, cb: Function) {
        return (data) => {
            const allowedTypes = {
                category: 'category',
                product: 'product',
                customer_account_login: 'login',
                checkout_cart_index: 'cart',
                catalogsearch_result_index: 'search',
                override_category: 'override_category',
            };
            if (allowedTypes[data.type]) {
                cb(this.processResults({ ...data, type: allowedTypes[data.type] }));
            } else {
                redirectToMagento(location);
            }
        };
    }

    processResults(data) {
        return {
            resourceType: data.type,
            resourceId: Number(data.id),
            storeId: this.setCurrentStore.getStoreCode(data.store_id),
        };
    }

    /* redirect on login page if user is not logged in */

    private redirectLoginPage(cookie: Cookie) {
        const storeCode = this.setCurrentStore.getCurrentStore();
        const isPortalStore = storeCode.startsWith('portal_');
        const isPortalLoginRequired = cookie.get('cw-m2-portal-login-required') !== '0';
        const isLoginRequired = isPortalStore &&
            !isPunchoutStoreMode() &&
            isPortalLoginRequired &&
            !window.location.pathname.match('/customer/account');

        if (isLoginRequired) {
            const referer = window.btoa('/');
            const redirect = '/customer/account/login/referer/';
            const queryString = new URLSearchParams(window.location.search);
            const paramCode = queryString.get('code');
            const urlQuery = paramCode ? `?code=${paramCode}` : '';
            const redirectUrl = {
                pathname: redirect + referer + urlQuery, search: '', hash: '', state: undefined,
            };
            redirectToMagento(redirectUrl);
        }
        return this;
    }
}
