import React from 'react';
import { IUsePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { useContainerHook } from '@silkpwa/redux';
import { classes } from '@silkpwa/module/util/classes';
import styles from './styles.css';
import { SwatchOptionType } from '../swatches';

const getStyle = (label: string) => {
    if (!label || label.length <= 3) return {};

    return {
        fontSize: '0.8em',
    };
};

interface ITextSwatchProps {
    option: SwatchOptionType;
    dataTest: string;
    theme: { [key: string]: any };
}
/* eslint-disable react/no-danger */
export const TextSwatch: React.FC<ITextSwatchProps> = ({ theme, dataTest, option }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    const roundPrice = usePriceRounder();
    let symbol = '+'; // default to plus
    let { upcharge } = option;
    if (option.upcharge && option.upcharge < 0) {
        symbol = '−'; // negative upcharge
        upcharge = Math.abs(option.upcharge);
    }
    return (
        <div
            className={theme.swatch}
            data-test={dataTest}
            style={getStyle(option.label)}
        >
            <span
                className={classes({ [styles.upcharge]: option.upcharge })}
                data-test="upcharge"
                dangerouslySetInnerHTML={{
                    __html: option.upcharge ? `${symbol}${formatPrice(roundPrice(upcharge))}` : '',
                }}
            />
            {option.label}
            <span
                className={styles.stockLabel}
                data-test="inventory_stock_message"
                dangerouslySetInnerHTML={{
                    __html: option.stockLabel,
                }}
            />
        </div>
    );
};
/* eslint-enable */
