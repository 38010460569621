import React, { useEffect, useState } from 'react';
import styles from './style.css';
import { IProductConfigData } from '../product-config';
import { BundleSelectionRadio } from './bundle-selection-radio';
import { groupSelectionsForSwatches, groupColorSwatchImages } from './group-selections-for-swatches';
import { BundleSelectionSelect } from './bundle-selection-select';

/* eslint-disable camelcase */
export interface IBundleOptionData {
    id: string;
    required: string;
    selections: Array<IBundleSelectionData>;
    show_single: number;
    title: string;
    type: string;
}

export interface IBundleSelectionData {
    can_change_qty: string;
    stock_message: string;
    is_item_saleable: number;
    backorder_message: string;
    product_alert_message: string;
    product_id: number;
    default_qty: number;
    is_default: string;
    is_saleable: string;
    price: number;
    selection_id: string;
    selection_title: string;
    sku: string;
    tier_price: Array<number>;
    type_id: string;
    color: string;
    color_swatch_image: string;
    size: string;
}
/* eslint-enable camelcase */

interface IBundleOptionProps {
    bundleOption: IBundleOptionData;
    bundleConfig: IProductConfigData;
}

export type SetSelectedBundleSelectionFn = (selected: string, selectedQuantity: string) => void;

export const BundleOption: React.FC<IBundleOptionProps> = ({
    bundleOption,
    bundleConfig,
}) => {
    const currentSelections = bundleConfig.selections;
    const currentSelectionsQty = bundleConfig.selectionsQty;
    const bundleOptionId = bundleOption.id;
    let currentSelection = '';
    let currentSelectionQty: string | number = '1';

    if (
        currentSelections &&
        Object.keys(currentSelections).length > 0 &&
        currentSelections[bundleOptionId]
    ) {
        currentSelection = currentSelections[bundleOptionId];
        currentSelectionQty = currentSelectionsQty
            ? currentSelectionsQty[bundleOptionId] : currentSelectionQty;
    }

    const [selected, setSelected] = useState(currentSelection);
    useEffect(() => setSelected(currentSelection), [currentSelection]);

    const RADIO_INPUT_TYPE = 'radio';
    const SELECT_INPUT_TYPE = 'select';

    const setSelectedBundleSelection: SetSelectedBundleSelectionFn = (
        selectedId: string,
        selectedQuantity: string,
    ) => {
        if (!bundleConfig.setSelections) {
            throw new Error('HDIGH: bundleConfig.setSelections is undefined');
        }
        bundleConfig.setSelections(bundleOption.id, selectedId, selectedQuantity);
        setSelected(selectedId);
    };

    return (
        <div className={styles.bundleOptionContainer}>
            <div className={styles.bundleOptionTitle}>
                <b>{bundleOption.title}</b>
                {bundleOption.required ? (
                    <span className={styles.bundleOptionRequired}>*</span>
                ) : ''}
            </div>
            <div className={styles.bundleSelectionsWrapper}>
                {bundleOption.selections.length > 0 ? (() => {
                    switch (bundleOption.type) {
                        case SELECT_INPUT_TYPE:
                            return (
                                <BundleSelectionSelect
                                    mappedSelections={groupSelectionsForSwatches(
                                        bundleOption.selections,
                                    )}
                                    setSelectedBundleSelection={setSelectedBundleSelection}
                                    originalSelections={bundleOption.selections}
                                    selectedSelectionId={selected}
                                    selectedSelectionQty={currentSelectionQty}
                                    mappedColorSwatchImages={groupColorSwatchImages(
                                        bundleOption.selections,
                                    )}
                                    bundleConfig={bundleConfig}
                                />
                            );
                        case RADIO_INPUT_TYPE:
                            return (
                                <ul className={styles.bundleSelectionList} data-test="bundle-option">
                                    {bundleOption.selections.map(selection => (
                                        <BundleSelectionRadio
                                            key={selection.selection_id}
                                            bundleSelection={selection}
                                            selected={selected}
                                            selectedQuantity={currentSelectionQty}
                                            setSelectedBundleSelection={setSelectedBundleSelection}
                                            hideInput={currentSelection !== '' && bundleOption.selections.length === 1}
                                        />
                                    ))}
                                </ul>
                            );
                        default:
                            // TODO: Implement other input types
                            return '';
                    }
                })() : ''}
            </div>
        </div>
    );
};
