import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const Fields = ({
    children = {},
    className = '',
    id = '',
}) => (
    <div
        className={classes(
        styles.fields,
        className,
    )}
        id={id}
    >
        {children}
    </div>
);
