import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IScrollOnHoverProps {
    className?: string;
}

export const ScrollOnHover: React.FC<IScrollOnHoverProps> = ({ className = '', children }) => (
    <div className={classes(styles.scrollOnHover, className)}>
        {children}
    </div>
);
