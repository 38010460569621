import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { connectProductReviews } from '@silkpwa/module/react-component/connect-product-reviews';
import { injectProps } from '@silkpwa/redux';
import { ReviewForm } from '../review-form';
import style from './style.css';
import { StarRating } from './snippet/rating';
import { ReviewMetrics } from './metrics';
import { SizingSlider } from './snippet/sizing';
import { Section } from '../components/section';
import { ScrollToElementBtn } from '../hooks/scroll-to-element-btn';

const injectWindow = injectProps('window');

const isReviews = window => (
    window.location.hash &&
    window.location.hash.indexOf('#write-review') > -1
);

interface ReviewsSnippetImplProps {
    productId: number;
    productReviews: [];
}

export const ReviewsSnippetImpl: React.FC<ReviewsSnippetImplProps> = ({ productId, productReviews }) => {
    const t = usePhraseTranslater();
    const {
        averageRating, averageRecommends, averageSizing, reviewsCount,
    } = ReviewMetrics(productReviews);
    const customDivStyle = { display: reviewsCount > 0 ? 'flex' : 'none' };
    const reviewsCountText = reviewsCount > 0 ? `${reviewsCount} Reviews` : 'No reviews';
    return (
        <>
            {!isReviews(window) && (
                <div className={style.war}>
                    <Section className={style.reviewSnippetContainer}>
                        <div className={classes(style.snippetStarsRecoInline, style.snippetCompact)}>
                            <div className={style.snippetStarsRecoStars}>
                                <div className={style.snippet}>
                                    <div className={style.snippetStarsContainer}>
                                        <div
                                            className={classes(
                                                style.snippetStars,
                                                style.snippetStarsPng,
                                            )}
                                            role="img"
                                        >
                                            <div className={style.ratingStars}>
                                                <StarRating ratingPercentage={averageRating} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.snippetReadAndWrite}>
                                        <ScrollToElementBtn
                                            elementId="review-display"
                                            btnText={reviewsCountText}
                                            className={style.snippetReviewCount}
                                            isDisableScrollText="No reviews"
                                        />
                                        <a
                                            href={`${window.location.href}#write-review`}
                                            className={classes(
                                                style.snippetWriteReviewLink,
                                                style.underline,
                                            )}
                                            rel="nofollow"
                                        >
                                            {t('Write a Review')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={style.snippetStarsRecoReco} style={customDivStyle}>
                                <div className={style.snippetRecoToFriend}>
                                    <div className={classes(
                                        style.snippetRecoToFriendPercent,
                                        style.snippetRecoToFriendGreen,
                                    )}
                                    >
                                        <span className={style.checkboxIcon}>
                                            <svg viewBox="0 0 176 176" focusable="false" aria-hidden="true">
                                                <circle
                                                    fill="#0A8900"
                                                    stroke="white"
                                                    strokeWidth="8"
                                                    cx="88"
                                                    cy="88"
                                                    r="81.2"
                                                />
                                                <path
                                                    fill="white"
                                                    stroke="white"
                                                    strokeWidth="5"
                                                    d="M77.2 121.3L43 87.2l5.5-5.6 28.7 28.6 50.3-50.3 5.5 5.6"
                                                />
                                            </svg>
                                        </span>
                                        <div className={classes(style.reco, style.recoGreen)}>
                                            <span className={style.recoValue}>
                                                {`${averageRecommends}%`}
                                            </span>
                                        </div>
                                    </div>
                                    <span className={style.recoToFriendMessage}>
                                        {t('of respondents would recommend this to a friend')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={style.snippetSliderRoot} style={customDivStyle}>
                            <div className={style.snippetTitle}>{t('Sizing')}</div>
                            <div className={classes(style.sliderRoot, style.snippetSlider)}>
                                <div className={style.minText}>
                                    {t('Feels full size too small')}
                                </div>
                                <div className={style.slider} role="img">
                                    <div className={style.sliderNodeGroup}>
                                        <SizingSlider
                                            averageSizing={averageSizing}
                                        />
                                    </div>
                                    <div className={style.sliderLine} />
                                </div>
                                <div className={style.maxText}>{t('Feels full size too big')}</div>
                            </div>
                        </div>
                    </Section>
                </div>
            )}
            {isReviews(window) && (
                <span className={style.reviewFormPageLink}>
                    <ReviewForm productId={productId} />
                </span>
            )}
        </>
    );
};

const ConnectedReviewSnippet = injectWindow(connectProductReviews(ReviewsSnippetImpl));

export { ConnectedReviewSnippet as ReviewsSnippet };
