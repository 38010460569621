import React from 'react';
import { isHeaderNavStyleCompact } from 'ui/component/header/util/is-header-nav-style-compact';
import { SubMenuIcon } from './sub-menu-icon';
import { SubMenu } from './sub-menu';

interface IMenuItemProps {
    subMenuClassName: string;
    className: string;
    category: any; // TODO: add typing
    level: number;
    index: number;
    siblingCount: number;
    headerNavStyle?: string;
}

const getStyle = (level, siblingCount) => {
    if (level > 1) {
        // put items onto two lines in sub menu
        const numPerLine = Math.ceil(siblingCount / 2);
        const width = `${(1 / numPerLine) * 100}%`;
        return {
            width,
        };
    }
    return {};
};

export const MenuItem: React.FC<IMenuItemProps> = ({
    subMenuClassName,
    className,
    category,
    level,
    index,
    siblingCount,
    headerNavStyle = '',
}) => {
    const handleClick = () => {
        /**
         * Here, when a customer clicks on any menu url (categories) we should:
         * 1. Reset previously added `searchFilter` GET params in order to avoid wrongly added search params on a PDP
         * when a customer came from e.g. `Recently Viewed` widget on a PDP which belongs to another category;
         * 2. The rest logic is in the `pwa-theme/packages/chefworks-theme/src/ui/component/breadcrumbs/breadcrumbs.tsx`
         *
         * TODO: need to consider clearing `searchFilter` on any navigation away/click or history event which
         *  signifies the search context should be discontinued.
         */
        sessionStorage.setItem('searchFilter', '');
    };
    const isNavStyleCompact = isHeaderNavStyleCompact(headerNavStyle);
    // Currently we don't have support for nth level on other menu, native pages and backend
    // Only on PWA normal menu it's accidentally showing 2nd level categories on compact nav style
    const allowOnlyFirstLevelCategory = level < 2;
    return (
        <li className={className} style={getStyle(level, siblingCount)} data-test="cat-menu-item">
            <a href={category.url} data-test="cat-link" onClick={handleClick}>
                {!isNavStyleCompact && (<SubMenuIcon level={level} icon={category.menuIcon} />)}
                <span>{category.name}</span>
            </a>
            {category.children.length > 0 && allowOnlyFirstLevelCategory && (
                <SubMenu
                    className={subMenuClassName}
                    index={index}
                    isNavStyleCompact={isNavStyleCompact}
                    headerNavStyle={headerNavStyle}
                >
                    {category.children.map((childCategory, childIndex) => (
                        <MenuItem
                            key={childCategory.name}
                            className={className}
                            subMenuClassName={subMenuClassName}
                            category={childCategory}
                            level={level + 1}
                            siblingCount={category.children.length}
                            index={childIndex}
                            headerNavStyle={headerNavStyle}
                        />
                    ))}
                </SubMenu>
            )}
        </li>
    );
};
