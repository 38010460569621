import React from 'react';

export const Section = ({
    id = '',
    className = '',
    children = {},
}) => (
    <div
        id={id}
        className={className}
    >
        {children}
    </div>
);
