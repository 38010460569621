const typePatterns = [
    {
        type: 'studioFront',
        pattern: /^.*sf(_|-|\d)*\.jpg/,
    },
    {
        type: 'studioBack',
        pattern: /^.*sb(_|-|\d)*\.jpg/,
    },
    {
        type: 'detail',
        pattern: /^.*d(_|-|\d)*\.jpg/,
    },
    {
        type: 'flat',
        pattern: /^.*fl(_|-|\d)*\.jpg/,
    },
];

export const typeClassifier = (img) => {
    const type = typePatterns
        .filter(x => img.thumb.match(x.pattern))
        .map(x => x.type)[0];
    return type || img.type;
};
