import React from 'react';
import { Container } from 'ui/component/container';
import styles from './style.css';

export const BottomContent: React.FC = () => (
    <div className={styles.bottom}>
        <Container id="footer-secure-image" className={styles.secureImage} />
        <Container id="footer-bottom-links" className={styles.links} />
        <Container id="footer-copyright" className={styles.copyright} />
    </div>
);
