import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SubItem } from './sub-item';
import styles from './style.css';

export const SubMenu = ({ animatedAccordion, category }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.subMenu} style={animatedAccordion.heightStyle}>
            <div ref={animatedAccordion.setMeasureRef}>
                <div>
                    <ul>
                        {category.children.map(childCategory => (
                            <SubItem
                                key={childCategory.name}
                                category={childCategory}
                            />
                        ))}
                    </ul>
                </div>
                <a href={category.url} className={styles.showAll}>
                    {t('Shop all')}
                    {' '}
                    {category.name}
                </a>
            </div>
        </div>
    );
};
