import { connect } from '@silkpwa/redux';
import { Category as EcommerceCategory } from '@silkpwa/module/ecommerce-catalog/override-category/override-category';
import { IBreadcrumb } from '@silkpwa/module/react-component/breadcrumbs/breadcrumbs';
import { memoize } from '../../util/memoize';

const createMenu = (root, categories) => root.filter(id => categories[id]).map(id => ({
    ...categories[id],
    children: createMenu(categories[id].children, categories),
}));

export interface IMenuItem {
    breadcrumbs: IBreadcrumb[];
    children: IMenuItem[];
    id: number;
    menuIcon: string;
    name: string;
    parentId: number;
    url: string;
}

export const connectOverrideCategoryMenu = connect({
    using: ['overrideCategory'],
    mapStateToProps: (overrideCategory: EcommerceCategory) => {
        const selectMenu = memoize(createMenu);
        return state => ({
            menu: selectMenu(
                overrideCategory.selectors.getRoot(state),
                overrideCategory.selectors.getCategories(state),
            ),
        });
    },
});
