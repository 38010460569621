import { loadScript } from '@silkpwa/module/util/load-script';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

declare global {
    interface Window {
        SLI: any;
        sliAutocomplete: any;
    }
}

export const getSLIScriptUrl = (config: SilkRestappDataConfigInfoInterface) => (
    config.extension_attributes?.sli_script_url
);

export const submitSLIForm = () => {
    window.SLI.searchSubmit();
};

export const cleanupSLI = () => {
    try {
        const elm = window.sliAutocomplete.wrapper.context;
        elm.parentNode.removeChild(elm);
    } catch (e) { /* ignore error */ }
};

export const startSLI = () => {
    if (!window.SLI.Autocomplete) {
        return;
    }

    window.SLI.Autocomplete.prototype.zIndex = 9999999;
    window.sliAutocomplete.select = new window.SLI.Autocomplete(window.SLI.rac);
    window.sliAutocomplete.wrapper = window.SLI.jQuery(window.sliAutocomplete.select.el);
};

export const restartSLI = () => {
    cleanupSLI();
    startSLI();
};

export const loadSLI = async (config: SilkRestappDataConfigInfoInterface) => {
    const scriptJq = 'https://code.jquery.com/jquery-2.2.4.min.js';
    const script = getSLIScriptUrl(config);
    await loadScript(scriptJq);
    await loadScript(script);
    restartSLI();
};

/*
Below seems unnecessary:
const SLI = window.SLI || {};

if (!SLI.findKeyword) {
    SLI.findKeyword = function (target) {
        let parent = target.parentElement;
        while (parent && !parent.nodeName.match(/html/i)) {
            const input = parent.querySelector('input[name="w"]');
            if (!input) {
                parent = parent.parentElement;
                continue;
            }
            return input.value;
        }
        return '';
    };
}
if (!SLI.searchSubmit) {
    SLI.searchSubmit = function (caller, event) {
        if (typeof (event) !== 'undefined') {
            var target = event.target ? event.target : window.event.srcElement;
        } else {
            var target = caller;
        }
        if (typeof (event) !== 'undefined') {
            event.preventDefault ? event.preventDefault() : event.returnValue = false;
        }
        let keyword = SLI.findKeyword(target);
        keyword = keyword.replace(/^\s+|\s+$/g, '');
        if (keyword == '') {
            return false;
        }
        keyword = encodeURIComponent(keyword);
        window.location =
        `${location.protocol}//${location.host}/search/go?ts=custom-us&w=${keyword}`;
        return false;
    };
}
*/
