import React from 'react';
import { routerStateKey } from '@silkpwa/module/react-component/router-state-key';
import { connectCategoryMenu } from '@silkpwa/module/react-component/connect-category-menu';
import { NavigationRichSnippet } from 'ui/component/rich-snippets/navigation-rich-snippet';
import { classes } from '@silkpwa/module/util/classes';
import { MenuItem } from './menu-item';
import styles from './style.css';

const Menu = ({ menu, stateKey, headerNavStyle = '' }) => (
    <>
        <NavigationRichSnippet navigation={menu} />
        <ul className={classes(styles.menuRoot, styles[headerNavStyle])} key={stateKey} data-test="cat-menu">
            {menu.map((category, index) => (
                <MenuItem
                    key={category.name}
                    className={styles.menuCategory}
                    subMenuClassName={styles.subMenu}
                    category={category}
                    level={1}
                    index={index}
                    siblingCount={menu.length}
                    headerNavStyle={headerNavStyle}
                />
            ))}
        </ul>
    </>
);

const ConnectedMenu = routerStateKey(connectCategoryMenu(Menu));

export { ConnectedMenu as Menu };
