import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { SubMenu } from '../sub-menu';
import styles from './style.css';

export const TopItem = ({ accordion, animatedAccordion, category }) => {
    const isSubMenuAvailable = !!category.children.length;

    return (
        <li
            className={classes(styles.topItem, {
                [styles.active]: accordion.isActive(category.name),
            })}
            ref={animatedAccordion.scroll(300, {
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth',
            })}
        >
            { isSubMenuAvailable
                ? (
                    <>
                        <button onClick={animatedAccordion.toggle} type="button">
                            <i className="fas fa-chevron-left" />
                            <span>{category.name}</span>
                            <i className="fas fa-chevron-right" />
                        </button>
                        <SubMenu category={category} animatedAccordion={animatedAccordion} />
                    </>
                )
                : (
                    <>
                        <button type="button" className={styles.showAllButton}>
                            <a href={category.url}>
                                {category.name}
                            </a>
                        </button>
                    </>
                )
            }
        </li>
    );
};
