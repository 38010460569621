export function getProductAttribute(product, attributeCode: string): any /* any OK */ {
    const attributes = product.attributes || [];
    for (let i = 0; i < attributes.length; i += 1) {
        const attr = product.attributes[i];
        if (attr.code === attributeCode) {
            return attr.value;
        }
    }
    return null;
}
