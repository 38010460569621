/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';

export interface IAgreement {
    checkbox_text: string;
    content: string;
    id: string;
    name: string;
}

export interface IStoreConfig {
    chefworks_checkout_expand_steps: string;
    chefworks_checkout_validation_city: string;
    chefworks_checkout_validation_country: string;
    chefworks_checkout_validation_firstname: string;
    chefworks_checkout_validation_lastname: string;
    chefworks_checkout_validation_postcode: string;
    chefworks_checkout_validation_region: string;
    chefworks_checkout_validation_street: string;
    chefworks_checkout_validation_telephone: string;
    recaptcha_sitekey: string;
    terms_and_conditions_status: string;
    terms_and_conditions_agreements: IAgreement[];
    is_portal: string;
    google_address_completion: string;
    google_address_completion_api_key: string;
    liquid_pixel_enabled: string;
    liquid_pixel_base_url: string;
    password_minimum_password_length: number;
    password_required_character_classes_number: number;
    general_country_default: string;
    loyalty_config: ILoyaltyConfig;
    creditkey_config: ICreditKeyConfig;
    base_currency_code: string;
    default_display_currency_code: string;
    chefworks_checkout_po_number_enabled: boolean;
    chefworks_checkout_create_account_default_heading: string;
    chefworks_checkout_create_account_default_label: string;
    chefworks_checkout_create_account_loyalty_heading: string;
    chefworks_checkout_create_account_loyalty_subheading: string;
    chefworks_checkout_create_account_loyalty_label: string;
}

interface ILoyaltyConfig {
    isLoyaltyProgramEnabled: boolean;
    paidTierSku: string[];
    checkoutYouEarnLabel: string;
    checkoutYouSpendLabel: string;
    checkoutRewardsDiscountLabel: string;
}

export interface ICreditKeyConfig {
    endpoint: string;
    publicKey: string;
    checkoutMode: string;
    redirectUrl: string;
    isCreditKeyDisplayed: boolean;
}

export interface IConfig {
    storeConfig: IStoreConfig;
}

export const GET_STORE_CONFIG: TypedDocumentNode<IConfig> = gql`
    query GET_STORE_CONFIG {
        storeConfig {
            chefworks_checkout_expand_steps
            chefworks_checkout_validation_city
            chefworks_checkout_validation_country
            chefworks_checkout_validation_firstname
            chefworks_checkout_validation_lastname
            chefworks_checkout_validation_postcode
            chefworks_checkout_validation_region
            chefworks_checkout_validation_street
            chefworks_checkout_validation_telephone
            recaptcha_sitekey
            terms_and_conditions_status
            terms_and_conditions_agreements {
                checkbox_text
                content
                id
                name
            }
            is_portal
            google_address_completion
            google_address_completion_api_key
            liquid_pixel_enabled
            liquid_pixel_base_url
            password_minimum_password_length
            password_required_character_classes_number
            general_country_default
            loyalty_config {
                isLoyaltyProgramEnabled
                paidTierSku
                checkoutYouEarnLabel
                checkoutYouSpendLabel
                checkoutRewardsDiscountLabel
            }
            creditkey_config {
                endpoint
                publicKey
                checkoutMode
                redirectUrl
                isCreditKeyDisplayed
            }
            base_currency_code
            default_display_currency_code
            chefworks_checkout_po_number_enabled
            chefworks_checkout_create_account_default_heading
            chefworks_checkout_create_account_default_label
            chefworks_checkout_create_account_loyalty_heading
            chefworks_checkout_create_account_loyalty_subheading
            chefworks_checkout_create_account_loyalty_label
        }
    }
`;
