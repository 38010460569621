import React from 'react';
import { CookiePrivacy } from 'ui/component/cookie-privacy';
import { useStoreMode } from '@silkpwa/module/store-mode';
import { usePortalInfo } from '@silkpwa/module/portal';
import { PortalData } from 'ui/component/portal/portal-data/portal-data';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { connectRouter } from '@silkpwa/module/react-component/connect-router';
import { DotdigitalIntegration } from 'ui/component/dotdigital-integration/dotdigital-integration';
import { TopFooter } from './top-footer';
import { MainContent } from './main-content';
import { BottomContent } from './bottom-content';
import { RecentlyViewedFooter } from './recently-viewed-footer';
import styles from './style.css';

interface IFooterProps {
    currentLocation: string;
}

const Footer: React.FC<IFooterProps> = ({ currentLocation }) => {
    const mode = useStoreMode();
    const {
        isPortal,
        isForceLogin,
        portal,
        isCustomerLoggedIn,
    } = usePortalInfo();
    const hasPortalData = Boolean(portal && portal.entity_id && portal.is_active);
    const ecommerceConfig = useConfig();
    if (!mode.isRetail) return null;

    if (currentLocation.startsWith('/checkout') &&
        !currentLocation.startsWith('/checkout/cart') &&
        !currentLocation.startsWith('/checkout/success') &&
        !currentLocation.startsWith('/checkout/index/index')
    ) {
        return null;
    }

    return (
        <footer className={styles.footer}>
            <RecentlyViewedFooter />
            {isPortal && (isForceLogin || hasPortalData) ? (
                <>
                    <PortalData
                        area="footerNavigation"
                        data={portal}
                        isCustomerLoggedIn={isCustomerLoggedIn}
                    />
                    <PortalData
                        area="disclaimer"
                        data={portal}
                        isCustomerLoggedIn={isCustomerLoggedIn}
                    />
                </>
            ) : (
                <>
                    <TopFooter />
                    <MainContent />
                </>
            )}
            <div className={styles.footerImage} />
            <BottomContent />
            <CookiePrivacy />
            <div className={styles.footerPadding} />
            <DotdigitalIntegration data={ecommerceConfig} />
        </footer>
    );
};

const ConnectedFooter = connectRouter(Footer);

export { ConnectedFooter as Footer };
