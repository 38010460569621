import React, { RefObject, SyntheticEvent } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { connectRouter } from '@silkpwa/module/react-component/connect-router';
import { connectConfig } from '@silkpwa/module/react-component/connect-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { LiveSearch as LiveSearchApi } from '@magento/storefront-search-as-you-type';
import { ProductSearchResponse } from '@magento/storefront-search-as-you-type/dist/@types/types/interface';
import { StoreDetailsProps } from '@magento/storefront-search-as-you-type/dist/@types/utils/LiveSearchAutocomplete';
import { keys } from '@silkpwa/module/util/keys';
import { getLiveSearchConfig } from './get-live-search-config';
import { Autocomplete } from './autocomplete';
import styles from './styles.css';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface LiveSearchProps {
    account: AccountState;
    isLiveSearchEnabled: boolean;
    liveSearchConfig: StoreDetailsProps|undefined;
    performSearch: any;
    pageSize?: number;
    minQueryLength?: number;
    currencySymbol: string;
    container: string;
    shouldFocus: boolean;
    ecommerceConfig: any;
    navigate: (location: string) => void;
    toggleSearch?: () => void;
    t: any;
}

interface LiveSearchWrapperProps {
    account: AccountState;
    isLiveSearchEnabled: boolean;
    navigate?: any;
    stateKey?: any;
    toggleSearch?: any;
    ecommerceConfig: SilkRestappDataConfigInfoInterface;
    container: string;
    shouldFocus: boolean;
}

interface LiveSearchState {
    productSearchResponse: ProductSearchResponse|null;
}

const enablePageScrolling = () => document.body.classList.remove('stop-scrolling');

const disablePageScrolling = () => document.body.classList.add('stop-scrolling');

class LiveSearch extends React.Component<LiveSearchProps, LiveSearchState> {
    private readonly searchInput: RefObject<HTMLInputElement> = React.createRef();

    private readonly closePopupButton: RefObject<HTMLButtonElement> = React.createRef();

    private readonly liveSearchWrapper: RefObject<HTMLDivElement> = React.createRef();

    private readonly liveSearchBar: RefObject<HTMLDivElement> = React.createRef();

    private readonly masthead: RefObject<HTMLDivElement> = React.createRef();

    private readonly searchPath: string = '/catalogsearch/result';

    private readonly formAction: string = '';

    constructor(props: LiveSearchProps) {
        super(props);

        this.state = {
            productSearchResponse: null,
        };

        const { location } = window;
        this.formAction = `${location.protocol}//${location.host}${this.searchPath}`;

        this.setProductSearchResponse = this.setProductSearchResponse.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.performSearch = this.performSearch.bind(this);
        this.handleEscape = this.handleEscape.bind(this);
        this.handleUserInout = this.handleUserInout.bind(this);
        this.handleSearchPopupButtonClick = this.handleSearchPopupButtonClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.showMasthead = this.showMasthead.bind(this);
        this.hideMasthead = this.hideMasthead.bind(this);
    }

    componentDidMount(): void {
        const { isLSDisabled } = this;
        if (isLSDisabled) {
            return;
        }

        document.addEventListener('mousedown', this.handleClickOutside);

        const { shouldFocus } = this.props;
        if (shouldFocus && this.searchInput.current) {
            this.searchInput.current.focus();
        }

        document.addEventListener('keydown', this.handleEscape, false);
        if (this.searchInput.current) {
            this.searchInput.current.addEventListener('keydown', this.handleUserInout, false);
        }

        if (this.closePopupButton.current) {
            this.closePopupButton.current.addEventListener('click', this.handleSearchPopupButtonClick, false);
        }
    }

    componentWillUnmount(): void {
        const { isLSDisabled } = this;
        if (isLSDisabled) {
            return;
        }

        document.removeEventListener('mousedown', this.handleClickOutside);

        document.removeEventListener('keydown', this.handleEscape, false);
        if (this.searchInput.current) {
            this.searchInput.current.addEventListener('keydown', this.handleUserInout, false);
        }

        if (this.closePopupButton.current) {
            this.closePopupButton.current.addEventListener('click', this.handleSearchPopupButtonClick, false);
        }
    }

    get mastheadElement() {
        return this.masthead.current;
    }

    get liveSearchWrap() {
        return this.liveSearchWrapper.current;
    }

    get liveSearchBarElement() {
        return this.liveSearchBar.current;
    }

    get isLSDisabled(): boolean {
        const { isLiveSearchEnabled, liveSearchConfig } = this.props;
        return !isLiveSearchEnabled || !liveSearchConfig ||
            !liveSearchConfig.environmentId || !liveSearchConfig.websiteCode ||
            !liveSearchConfig.storeCode || !liveSearchConfig.storeViewCode ||
            !liveSearchConfig.context || !liveSearchConfig.context.customerGroup;
    }

    get isSearchBarActive(): boolean {
        if (!this.liveSearchBarElement) {
            return false;
        }

        return this.liveSearchBarElement.classList.contains('is-active');
    }

    setProductSearchResponse(response: ProductSearchResponse|null): void {
        this.setState({
            productSearchResponse: response,
        });
    }

    submitNativeSearch(event: SyntheticEvent): void {
        event.preventDefault();
        event.stopPropagation();
        const { navigate } = this.props;
        const query = this.searchInput.current?.value ?? '';
        return navigate(`${this.searchPath}?q=${query}`);
    }

    formSubmit(event: SyntheticEvent) {
        if (this.searchInput.current && this.searchInput.current.value) {
            enablePageScrolling();
            return this.submitNativeSearch(event);
        }

        return '';
    }

    performSearch(query: string): void {
        const { isLSDisabled } = this;
        if (isLSDisabled) {
            return;
        }

        const { performSearch } = this.props;

        performSearch(query).then((response: ProductSearchResponse) => {
            this.setProductSearchResponse(response);
        }).catch((response: ProductSearchResponse) => {
            this.setProductSearchResponse(response);
        });
    }

    handleEscape(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            const { shouldFocus, toggleSearch } = this.props;
            if (shouldFocus && toggleSearch) {
                toggleSearch();
            }

            this.handleSearchPopupButtonClick(event);
        }
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event: any): void {
        if (this.liveSearchWrap && !this.liveSearchWrap.contains(event.target)) {
            enablePageScrolling();
            if (this.isSearchBarActive) {
                this.hideMasthead();
            }
        } else if (this.isSearchBarActive) {
            disablePageScrolling();
            this.showMasthead();
        }
    }

    handleUserInout(): void {
        const { container } = this.props;
        const searchBarCollection = document.getElementsByClassName('search-bar');
        let searchBar = searchBarCollection[0];
        /**
         * Closing search input in persistent-menu
         */
        if (container === 'normal-header' && searchBarCollection.length === 2) {
            [, searchBar] = Array.from(searchBarCollection);
        }

        if ((container === 'mobile-header' || container === 'persistent-header' || container === 'normal-header') &&
            this.searchInput.current && this.searchInput.current.value
        ) {
            if (searchBar && searchBar.classList) {
                searchBar.classList.add('is-active');
            }

            disablePageScrolling();
            this.showMasthead();
        }
    }

    handleSearchPopupButtonClick(event: Event): void {
        event.preventDefault();
        const { container } = this.props;

        const searchBar = document.getElementsByClassName('search-bar is-active')[0];
        if (container === 'mobile-header' || container === 'persistent-header' || container === 'normal-header') {
            if (searchBar && searchBar.classList) {
                searchBar.classList.remove('is-active');
            }

            enablePageScrolling();
            if (this.searchInput.current) {
                this.searchInput.current.value = '';
            }

            this.closeSearch();
        }
    }

    /**
     * Closing search input in persistent-menu
     */
    closeSearch(): void {
        const { toggleSearch } = this.props;
        /**
         * Closing search input in persistent-menu
         */
        if (toggleSearch) {
            toggleSearch();
        }

        this.hideMasthead();
    }

    showMasthead(): void {
        if (this.mastheadElement) {
            this.mastheadElement.style.display = 'block';
        }
    }

    hideMasthead(): void {
        if (this.mastheadElement) {
            this.mastheadElement.style.display = 'none';
        }
    }

    render() {
        const {
            currencySymbol,
            container,
            liveSearchConfig,
            t,
        } = this.props;
        const { productSearchResponse } = this.state;
        const minQueryLength = liveSearchConfig?.config.minQueryLength ?? 3;

        const isMobileHeader = container === 'mobile-header';
        const isDesktopHeader = container === 'normal-header' || container === 'persistent-header';
        const containerWrapperClass = container + '-wrapper'; // eslint-disable-line
        const searchForm = (
            <form
                className={classes(
                    'form minisearch ls_searchform',
                    styles.searchForm,
                    styles[container],
                    isDesktopHeader ? styles['desktop-header'] : '',
                )}
                action={this.formAction}
                method="GET"
                onSubmit={this.formSubmit}
                acceptCharset="utf-8"
            >
                <div className={styles.searchBox}>
                    <input
                        id="search"
                        type="text"
                        name="w"
                        ref={this.searchInput}
                        onKeyUp={(event) => {
                            const { value } = event.currentTarget;
                            const valueLength = value.length;
                            if (event.keyCode !== keys.ESCAPE && valueLength >= minQueryLength) {
                                this.performSearch(value);
                            }
                        }}
                        placeholder={t('search by keyword or item #')}
                        className="input-text ls_searchbox_textfield"
                        maxLength={128}
                        role="combobox"
                        aria-haspopup="false"
                        aria-autocomplete="both"
                        autoComplete="off"
                        data-provide="rac"
                        aria-owns="ls_raclist_suggestions ls_raclist_products ls_raclist_recent"
                        aria-controls="ls_raclist_suggestions ls_raclist_products ls_raclist_recent"
                        aria-expanded="false"
                        required
                    />
                    <input type="hidden" name="ts" value="custom-us" />
                    <button
                        type="submit"
                        className={styles.submitButton}
                    >
                        <i className="fas fa-search" />
                    </button>
                    <button
                        type="button"
                        className={styles.closePopupButton}
                        ref={this.closePopupButton}
                    >
                        <i className="fas fa-times" />
                    </button>
                </div>
                {!this.isLSDisabled && (
                    <div id="masthead" className={styles.masthead} ref={this.masthead}>
                        <div className={styles.autocompleteWrapper}>
                            <Autocomplete
                                productSearchResponse={productSearchResponse}
                                formSubmit={this.formSubmit}
                                formAction={this.formAction}
                                enablePageScrolling={enablePageScrolling}
                                currencySymbol={currencySymbol}
                                t={t}
                            />
                        </div>
                    </div>
                )}
            </form>
        );

        if (isMobileHeader) {
            return searchForm;
        }

        return (
            <div
                ref={this.liveSearchWrapper}
                className={classes(
                    styles.desktopHeader,
                    styles[containerWrapperClass],
                )}
            >
                <div
                    ref={this.liveSearchBar}
                    className={classes('desktop-header-search search-bar', styles.searchWrapper)}
                >
                    {searchForm}
                </div>
            </div>
        );
    }
}

const LiveSearchWrap = (
    {
        account,
        isLiveSearchEnabled,
        navigate,
        stateKey,
        ecommerceConfig,
        container,
        shouldFocus,
        toggleSearch = () => undefined,
    }: LiveSearchWrapperProps,
) => {
    const t = usePhraseTranslater();
    const isPunchoutInspectOperation: boolean = useIsPunchoutInspectOperation();
    const liveSearchConfig: StoreDetailsProps|undefined = getLiveSearchConfig(account, ecommerceConfig);

    const searchApi: LiveSearchApi|false = liveSearchConfig !== undefined
        ? new LiveSearchApi(liveSearchConfig)
        : false;

    const performSearch = searchApi ? searchApi.performSearch : null;
    const pageSize = searchApi ? searchApi.pageSize : 0;
    const minQueryLength = searchApi ? searchApi.minQueryLength : 0;
    const currencySymbol = searchApi ? searchApi.currencySymbol : '';

    return isPunchoutInspectOperation ? null : (
        <LiveSearch
            account={account}
            isLiveSearchEnabled={isLiveSearchEnabled}
            liveSearchConfig={liveSearchConfig}
            performSearch={performSearch}
            pageSize={pageSize}
            minQueryLength={minQueryLength}
            currencySymbol={currencySymbol}
            key={stateKey}
            navigate={navigate}
            ecommerceConfig={ecommerceConfig}
            container={container}
            shouldFocus={shouldFocus}
            toggleSearch={toggleSearch}
            t={t}
        />
    );
};

const ConnectedLiveSearch = connectAccount(connectConfig(connectRouter(LiveSearchWrap)));

export { ConnectedLiveSearch as LiveSearch };
