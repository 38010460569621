import React from 'react';
import { Container } from 'ui/component/container';
import styles from './style.css';

export const MainContent = () => (
    <div className={styles.main}>
        <div className={styles.left}>
            <Container id="footer-logo" />
            <Container id="footer-contact-info" />
        </div>
        <Container id="footer-left-links" className={styles.links} />
        <Container id="footer-right-links" className={styles.links} />
        <div className={styles.right}>
            <Container id="footer-icons" className={styles.icons} />
            <Container id="footer-buttons" className={styles.buttons} />
        </div>
    </div>
);
