import React from 'react';
import styles from './style.css';

export const Container: React.FC = ({ children }) => (
    <div className={styles.embeddedContainer}>
        <div className={styles.configWrap}>
            {children}
        </div>
    </div>
);
