/* eslint-disable no-console */
import React from 'react';
import { injectPropsDecorator } from '@silkpwa/redux';

const skipRecovery = process.env.NODE_ENV === 'development';

interface RestartOnFatalErrorProps {
    persist?: any;
    errorLogger?: any;
    renderError: any;
}

@injectPropsDecorator('persist')
export class RestartOnFatalError extends React.Component<RestartOnFatalErrorProps, any> {
    constructor(props) {
        super(props);

        this.state = { hasError: false, recovered: 'recovering' };
    }

    static getDerivedStateFromError(_) {
        return { hasError: true };
    }

    async componentDidCatch(error, info) {
        console.error('Fatal error', error, info);

        if (skipRecovery) {
            return;
        }

        const { persist, errorLogger } = this.props;

        // TODO: We need to add an error logging API that we can
        // call.
        if (errorLogger) {
            console.log('Logging fatal error to API');
            await errorLogger(error, info);
        }

        try {
            console.log('Disabling persistence');
            await persist.disable();
            console.log('Persistence disabled');
            this.setState({ recovered: 'success' }, () => {
                window.location.href = '/';
            });
        } catch (e) {
            console.log('Disabling persistence failed');
            this.setState({ recovered: 'failed' });
        }
    }

    render() {
        const { hasError, recovered } = this.state;
        const { renderError, children } = this.props;

        if (hasError) {
            return renderError(recovered);
        }

        return children;
    }
}
