import React from 'react';
import { BundleOption, IBundleOptionData } from './bundle-option';
import styles from './style.css';
import { IProductConfigData } from '../product-config';

interface BundleConfigProps {
    config: IProductConfigData;
}

export const BundleConfig: React.FC<BundleConfigProps> = ({
    config,
}) => (
    <div>
        <div id="validation-area" className={styles.attributes}>
            {config.product.bundledProducts.map((bundleOption: IBundleOptionData) => (
                <BundleOption
                    key={bundleOption.id}
                    bundleOption={bundleOption}
                    bundleConfig={config}
                />
            ))}
        </div>
    </div>
);
