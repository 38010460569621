import { TransformerRegistry } from './transformer-registry';
import { FlagTransformer } from './flag-transformer';
import { TextTransformer } from './text-transformer';
import { SpecialInstructionsTransformer } from './special-instructions-transformer';
import { LogoTransformer } from './logo-transformer';

const registry = new TransformerRegistry();

registry.registerTransformer('flags', new FlagTransformer());
registry.registerTransformer('logos', new LogoTransformer());
registry.registerTransformer(
    'special-instructions',
    new SpecialInstructionsTransformer(),
);
registry.registerTransformer('text', new TextTransformer());

export { registry };
