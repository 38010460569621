import React from 'react';
import { SLISearch } from 'ui/component/sli-search';
import { LiveSearch } from 'ui/component/live-search';
import { useSearchConfig } from 'ui/component/search-config/use-search-config';
import { classes } from '@silkpwa/module/util/classes';
import { useOverrideCategoriesInfo } from 'ui/component/override-product-listing-page/util/override-categories-info';
import { Menu } from './menu';
import styles from './style.css';
import { OverrideMenu } from './menu/override-menu';

export const HamburgerExpandedContent = (
    {
        open,
        showSearch,
        focusSearch,
        closeMenu,
    },
) => {
    const { isOverrideCategories, isVerticalMenu } = useOverrideCategoriesInfo();
    const includeSearchInHamBurger = false;
    const { isSliSearchEnabled, isLiveSearchEnabled } = useSearchConfig();
    const shouldShowSearch = includeSearchInHamBurger && open && showSearch;
    if (isOverrideCategories) {
        return !isVerticalMenu ? (
            <div
                className={classes({
                    [styles.menuWrap]: true,
                    [styles.open]: open,
                })}
            >
                <OverrideMenu key={open} closeMenu={closeMenu} isOpen={open} />
            </div>
        ) : null;
    }

    return (
        <div
            className={classes({
                    [styles.menuWrap]: true,
                    [styles.open]: open,
                })}
        >
            {shouldShowSearch && isSliSearchEnabled && (
            <SLISearch container="hamburger-header" shouldFocus={focusSearch} />
                )}
            {shouldShowSearch && !isSliSearchEnabled && (
            <LiveSearch
                container="hamburger-header"
                shouldFocus={focusSearch}
                isLiveSearchEnabled={isLiveSearchEnabled}
            />
                )}
            <Menu key={open} closeMenu={closeMenu} isOpen={open} />
        </div>
    );
};
