import React, { useEffect } from 'react';
import { reservedHeight as fixedReservedHeight } from 'ui/styles/reserved-height';
import { connectCategoryMenu } from '@silkpwa/module/react-component/connect-category-menu';
import { AccordionGroupState } from '@silkpwa/module/react-component/accordion-group-state';
import { AnimatedAccordion } from '@silkpwa/module/react-component/animated-accordion';
import { useScrollBlock } from 'chefworks-theme/src/hooks/useScrollBlock';
import { classes } from '@silkpwa/module/util/classes';
import { TopItem } from './top-item';
import styles from './style.css';

const searchHeight = 0;
const reservedHeight = fixedReservedHeight + searchHeight;

const MenuImpl = ({ menu, closeMenu, isOpen }) => {
    const [blockScroll, allowScroll] = useScrollBlock();
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        (isOpen === true) ? blockScroll() : allowScroll();
    }, [isOpen]);

    return (
        <AccordionGroupState>
            {accordionProps => (
                <div className={styles.overlay}>
                    {isOpen && (
                        <div className={styles.overlayBackground} onClick={closeMenu} aria-hidden="true">
                            <div className={styles.overlayControls} />
                        </div>
                    )}
                    <div className="overlayContainer">
                        <ul
                            className={classes(
                                styles.menu,
                                {
                                    'has-active-submenu': accordionProps.active !== null,
                                },
                            )}
                            style={{
                                maxHeight: `${window.innerHeight - reservedHeight}px`,
                            }}
                        >
                            {menu.map(category => (
                                <AnimatedAccordion
                                    key={category.name}
                                    withGroup={[accordionProps, category.name]}
                                >
                                    {animatedAccordion => (
                                        <TopItem
                                            category={category}
                                            animatedAccordion={animatedAccordion}
                                            accordion={accordionProps}
                                        />
                                    )}
                                </AnimatedAccordion>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </AccordionGroupState>
    );
};

export const Menu = connectCategoryMenu(MenuImpl);
