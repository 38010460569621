import React, { useState } from 'react';
import { ApolloProvider, useReactiveVar } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { useDesignConfig } from '@silkpwa/module/design-config';
import { cartSummaryItemsVar } from 'ui/page/checkout-page/checkout-state';
import { Cart } from 'ui/component/checkout/cart';
import { LoaderContext } from 'ui/page/checkout-page/checkout-loader';
import { CheckoutCartIcon } from '../icons/cart-icon';
import styles from './style.css';

export const CheckoutHeader = ({ isMobile }) => {
    // TODO: Investigate, if following useDesignConfig is responsible for calling app-category Rest API,
    //  then we need to avoid using it or prevent calling Rest API for Checkout
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const designConfig = useDesignConfig();
    const cartItems = useReactiveVar(cartSummaryItemsVar) || [];
    const [cartLoading, setCartLoading] = useState(false);
    const title = `${designConfig.pageTitle}`;
    const logoUrl = designConfig.checkoutLogoUrl ?? '';
    return (
        <>
            <ApolloProvider client={GraphQlClient}>
                <LoaderContext.Provider value={{ cartLoading, setCartLoading }}>
                    <div className={styles.checkoutHeader}>
                        <a
                            data-test="logo-link"
                            href={
                                isPunchoutInspectOperation ? window.location.href : '/'
                            }
                            className={styles.logo}
                        >
                            <img src={logoUrl} alt={title} />
                        </a>
                        {isMobile && (
                            <CheckoutCartIcon
                                cartItems={cartItems}
                                top="-15px"
                                right="-15px"
                                testAttribute="checkout-cart-icon"
                            >
                                {({ open, close }) => (open && (
                                    <div className={styles.cartWrapper}>
                                        <button
                                            type="button"
                                            onClick={close}
                                        >
                                            <i className="fa-solid fa-xmark" />
                                        </button>
                                        <Cart />
                                    </div>
                                ))}
                            </CheckoutCartIcon>
                        )}
                    </div>
                </LoaderContext.Provider>
            </ApolloProvider>
        </>
    );
};
