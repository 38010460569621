import { DuckModuleWithoutReducer, inject } from '@silkpwa/redux';
import { IReviewsRepository } from '@silkpwa/magento/api/reviews-repository';
import { ProductEntity } from '../product-entity/product-entity';
import { Router } from '../../router';
import { ICache, ICacheFactory } from '../../multistore';

@inject(
    'ecommerceProductEntity',
    'reviewsRepository',
    'StoreLevelCacheFactory',
    'router',
)
export class Reviews extends DuckModuleWithoutReducer {
    public readonly actions: Pick<Reviews, (
        'fetchReviews' |
        'fetchProductReviews' |
        'addReview' |
        'resetReview' |
        'uploadImages' |
        'uploadVideos' |
        'resetReviewAfterVote'
    )>;

    public readonly selectors: Pick<Reviews, (
        'getReviewAddedStatus' |
        'getAddingReviewStatus' |
        'getReviews' |
        'getErrors' |
        'getProductReviews' |
        'getProduct'
    )>;

    private readonly cache: ICache<any>;

    constructor(
        private products: ProductEntity,
        private reviewsRepository: IReviewsRepository,
        storeLevelCacheFactory: ICacheFactory,
        private router: Router,
    ) {
        super('Reviews');

        this.cache = storeLevelCacheFactory.create(
            'Reviews',
            this.reduceReviews.bind(this),
        );
        this.addDuck('cache', this.cache);

        this.cache.persistSlice(['reviews'], 0);
        this.cache.persistSlice(['productReviews'], 0);

        this.fetchReviews = this.fetchReviews.bind(this);
        this.fetchProductReviews = this.fetchProductReviews.bind(this);
        this.addReview = this.addReview.bind(this);
        this.getReviews = this.getReviews.bind(this);
        this.getErrors = this.getErrors.bind(this);
        this.getReviewAddedStatus = this.getReviewAddedStatus.bind(this);
        this.getAddingReviewStatus = this.getAddingReviewStatus.bind(this);
        this.getProductReviews = this.getProductReviews.bind(this);
        this.resetReview = this.resetReview.bind(this);
        this.resetReviewAfterVote = this.resetReviewAfterVote.bind(this);
        this.getProduct = this.getProduct.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.uploadVideos = this.uploadVideos.bind(this);

        this.actions = {
            fetchReviews: this.fetchReviews,
            fetchProductReviews: this.fetchProductReviews,
            addReview: this.addReview,
            resetReview: this.resetReview,
            resetReviewAfterVote: this.resetReviewAfterVote,
            uploadImages: this.uploadImages,
            uploadVideos: this.uploadVideos,
        };
        this.selectors = {
            getReviewAddedStatus: this.getReviewAddedStatus,
            getAddingReviewStatus: this.getAddingReviewStatus,
            getErrors: this.getErrors,
            getReviews: this.getReviews,
            getProductReviews: this.getProductReviews,
            getProduct: this.getProduct,
        };
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['SET_REVIEWS', 'SET_PRODUCT_REVIEWS', 'ADD_REVIEW', 'ADD_REVIEW_SUCCESS', 'ADD_REVIEW_FAILURE', 'RESET_REVIEW_STATE'];
    }

    /* eslint-disable-next-line class-methods-use-this */
    private get initialState() {
        return {
            reviews: [],
            productReviews: [],
            addingReview: false,
            reviewAdded: false,
            error: null,
        };
    }

    private reduceReviews(state = this.initialState, action) {
        switch (action.type) {
            case this.actionTypes.SET_REVIEWS:
                return {
                    ...state,
                    reviews: action.reviews,
                };
            case this.actionTypes.SET_PRODUCT_REVIEWS:
                return {
                    ...state,
                    productReviews: {
                        ...state.productReviews,
                        [action.productId]: action.productReviews,
                    },
                };
            case this.actionTypes.ADD_REVIEW:
                return {
                    ...state,
                    addingReview: true,
                    reviewAdded: false,
                    error: null,
                };

            case this.actionTypes.ADD_REVIEW_SUCCESS:
                return {
                    ...state,
                    addingReview: false,
                    reviews: [...state.reviews, action.payload],
                    reviewAdded: true,
                };
            case this.actionTypes.ADD_REVIEW_FAILURE:
                return {
                    ...state,
                    addingReview: false,
                    error: action.error,
                    reviewAdded: false,
                };
            case this.actionTypes.RESET_REVIEW_STATE:
                return {
                    ...state,
                    addingReview: false,
                    reviewAdded: false,
                    error: null,
                };
            case this.actionTypes.RESET_REVIEW_STATE_AFTER_VOTE:
                return {
                    ...state,
                    productReviews: {
                        ...state.productReviews,
                        [action.productId]: [],
                    },
                };
            default:
                return state;
        }
    }

    public async fetchReviews(dispatch) {
        const results = await this.reviewsRepository.getReviews();
        dispatch(this.cache.wrapAction({
            type: this.actionTypes.SET_REVIEWS,
            reviews: results.items,
        }));
    }

    public fetchProductReviews(productId, sort = 'date-desc') {
        return async (dispatch) => {
            const results = await this.reviewsRepository.getProductReviews(productId, sort);

            dispatch(this.cache.wrapAction({
                type: this.actionTypes.SET_PRODUCT_REVIEWS,
                productId,
                productReviews: results.items,
            }));
        };
    }

    public resetReview() {
        return async (dispatch) => {
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.RESET_REVIEW_STATE,
            }));
        };
    }

    public resetReviewAfterVote(productId) {
        return async (dispatch) => {
            dispatch({
                type: this.actionTypes.RESET_REVIEW_STATE_AFTER_VOTE,
                productId,
            });
        };
    }

    public addReview(data) {
        return async (dispatch) => {
            try {
                dispatch(this.cache.wrapAction({
                    type: this.actionTypes.ADD_REVIEW,
                }));
                const result = await this.reviewsRepository.addReview(data);
                dispatch(this.cache.wrapAction({
                    type: this.actionTypes.ADD_REVIEW_SUCCESS,
                    payload: result,
                }));
                return result;
            } catch (error) {
                dispatch(this.cache.wrapAction({
                    type: this.actionTypes.ADD_REVIEW_FAILURE,
                    error,
                }));
                return null;
            }
        };
    }

    public uploadImages(data) {
        return async () => {
            try {
                return await this.reviewsRepository.uploadImages(data);
            } catch (e) {
                throw new Error(e.response.message);
            }
        };
    }

    public uploadVideos(data) {
        return async () => {
            try {
                return await this.reviewsRepository.uploadVideos(data);
            } catch (e) {
                throw new Error(e.response.message);
            }
        };
    }

    public getReviews(state) {
        const { reviews } = this.cache.getCurrentState(state);
        return reviews;
    }

    public getErrors(state) {
        const { error } = this.cache.getCurrentState(state);
        return error;
    }

    public getReviewAddedStatus(state) {
        const { reviewAdded } = this.cache.getCurrentState(state);
        return reviewAdded;
    }

    public getAddingReviewStatus(state) {
        const { addingReview } = this.cache.getCurrentState(state);
        return addingReview;
    }

    // eslint-disable-next-line class-methods-use-this
    public getProduct(state) {
        const state1 = state['StoreLevelCache(ProductEntity)0']?.storeCache;
        const storeNames = state1 ? Object.keys(state1) : [];
        const storeName = storeNames.length > 0 ? storeNames[0] : null;
        const storeObject = storeName ? state1[storeName] : null;

        if (!state1 || !storeNames || !storeObject) {
            return null;
        }
        return storeObject.products;
    }

    public getProductReviews(state, productId) {
        const cache = this.cache.getCurrentState(state).productReviews;
        const productReviews = cache[productId] || [];
        this.products.selectors.getProducts(
            state,
            productReviews,
        );

        return productReviews;
    }

    public initialize(store) {
        this.router.handle((route) => {
            // wait until primary data is loaded to fetch reviews
            const unsubscribe = this.router.onPageLoaded(async () => {
                await store.dispatch(this.fetchReviews);
                route.done();
                unsubscribe();
            });
        });
        this.router.addHandler('product', async (route) => {
            const productId = route.resource.resourceId;
            const sort = 'date-desc'; // Provide a default value if sort is not available
            await store.dispatch(this.fetchProductReviews(
                productId,
                sort,
            ));
            route.done();
        });
    }
}
