import React, { useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { StoreSelectorView } from 'ui/component/store-selector';
import styles from './style.css';

const ActualIcon = () => {
    const [open, setOpen] = useState(false);
    return (
        <StoreSelectorView
            open={open}
            setOpen={setOpen}
        >
            <AccessibleButton
                tag="span"
                action={() => setOpen(!open)}
            >
                <i className={classes('fas', 'fa-globe-americas', styles.icon)} />
            </AccessibleButton>
        </StoreSelectorView>
    );
};

export const StoreSelectorIcon = () => {
    const config = useConfig();
    if (!config.extension_attributes?.store_switcher ||
        !config.store_config?.current_store.extension_attributes?.is_store_locator_enable
    ) {
        return null;
    }

    return (
        <ActualIcon />
    );
};
