import React from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { REMOVE_FROM_CART_MUTATION } from 'graphql/cart/cart';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { cartIdVar, cartVar } from 'ui/page/checkout-page/checkout-state';
import { useCheckoutLoader } from 'ui/page/checkout-page/checkout-loader';
import styles from './style.css';

export const RemoveItem = ({ item }) => {
    const t = usePhraseTranslater();
    const cartId = useReactiveVar(cartIdVar);
    const cartItemId = String(item.id);
    const { setCartLoading } = useCheckoutLoader();
    const [removeFromCart] = useMutation(REMOVE_FROM_CART_MUTATION);
    const handleRemove = async () => {
        setCartLoading(true);
        const response = await removeFromCart({
            variables: {
                input: {
                    cart_id: cartId,
                    cart_item_id: cartItemId,
                },
            },
            onError: () => {
                // catch errors
            },
        });
        // TODO: Add loading state
        const { data } = response;
        if (data?.removeItemFromCart.cart.id) {
            // console.log('Cart Id Updated : ', data.removeItemFromCart.cart.id);
            localStorage.setItem('cartId', data.removeItemFromCart.cart.id);
            cartIdVar(data.removeItemFromCart.cart.id);
            cartVar(data.removeItemFromCart.cart);
        }
        setCartLoading(false);
    };
    if (!item.userCanEdit) return null;
    return (
        <button
            className={styles.removeItem}
            onClick={handleRemove}
            type="button"
        >
            {t('Remove')}
        </button>
    );
};
