import React from 'react';
import styles from './styles.css';

interface FixedElementProps {
    className?: string;
    isPerformUpdates?: boolean;
}

export class FixedElement extends React.Component<FixedElementProps> {
    private pushRef;

    private containerRef;

    constructor(props) {
        super(props);

        this.setContainerRef = this.setContainerRef.bind(this);
        this.setPushRef = this.setPushRef.bind(this);
        this.componentDidUpdate = this.performUpdates.bind(this);
    }

    setContainerRef(e) {
        if (!e) return;
        this.containerRef = e;
        this.update();
    }

    setPushRef(e) {
        if (!e) return;
        this.pushRef = e;
        this.update();
    }

    performUpdates() {
        const { isPerformUpdates } = this.props;
        if (isPerformUpdates) this.update();
    }

    update() {
        if (!this.containerRef || !this.pushRef) return;
        const height = this.containerRef.clientHeight;
        this.pushRef.style.height = `${height}px`;
    }

    render() {
        const { children, className } = this.props;
        return (
            <div ref={this.setPushRef} className={styles.fixedElement}>
                <div className={className}>
                    <div ref={this.setContainerRef}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}
