import React from 'react';
import { useContainer } from '@silkpwa/redux';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';
import { Modal } from './modal';

interface InjectedModalProps {
    open: boolean;
    setOpen(open: boolean): void;
}

interface ProductConfiguratorModalProps {
    productId: number;
    itemId?: number;
    open?: boolean;
    isCart?: boolean;
    children(props: InjectedModalProps): JSX.Element;
}

export const ProductConfiguratorModal = ({
    open: startOpen,
    productId,
    itemId,
    isCart,
    children,
}: ProductConfiguratorModalProps) => {
    const appEventBus = useContainer<AppEventBus>('appEventBus');

    const wrapSetOpen = setOpen => (value) => {
        setOpen(value);
        if (!value) return;
        appEventBus.publish('chefworks.quickview.opened', productId);
    };

    return (
        <ModalSystemConsumer open={startOpen}>
            {({ open, setOpen, isTop }) => (
                <React.Fragment>
                    {open && (
                        <Modal
                            productId={productId}
                            isTop={isTop}
                            itemId={itemId}
                            handleExit={() => setOpen(false)}
                            isCart={isCart}
                        />
                    )}
                    {children({ open, setOpen: wrapSetOpen(setOpen) })}
                </React.Fragment>
            )}
        </ModalSystemConsumer>
    );
};
