export const ReviewMetrics = (productReviews) => {
    const reviewsCount = productReviews.filter(review => typeof review === 'object').length;
    const respondedToRecommend = (dataArray) => {
        if (dataArray.length < 1) {
            return 0;
        }
        const recommendValues = dataArray
            .filter(review => review.recommend !== 0)
            .map(review => review.recommend);
        return recommendValues.length;
    };
    const calculateAverageRating = (dataArray) => {
        if (dataArray.length < 1) {
            return 0;
        }
        const reviewsValues = dataArray.flatMap(review => review.rating_votes);
        const ratingsCount = reviewsValues.length;
        const sum = reviewsValues.reduce((acc, ratingVote) => acc + Number(ratingVote.percent), 0);
        const averageStars = reviewsCount > 0 ? sum / ratingsCount : 0;
        return parseFloat(averageStars.toFixed(1));
    };

    const calculateAverageRecommendsPercentage = (dataArray) => {
        const respondents = respondedToRecommend(dataArray);
        const sumOfRecommends = dataArray.reduce((acc, obj) => {
            if (obj.recommend === 1) { // "yes" recommends
                return acc + 1;
            }
            return acc;
        }, 0);
        return respondents > 0 ? (sumOfRecommends / respondents) * 100 : 0;
    };

    const calculateAverageSizing = (dataArray) => {
        const sumOfSizing = dataArray.reduce((acc, obj) => acc + Number(obj.sizing), 0);
        return reviewsCount > 0 ? sumOfSizing / reviewsCount : 0;
    };
    return {
        averageRating: calculateAverageRating(productReviews),
        averageRatingRound: Math.round(calculateAverageRating(productReviews)),
        averageRecommends: Math.round(calculateAverageRecommendsPercentage(productReviews)),
        averageSizing: Math.round(calculateAverageSizing(productReviews)),
        reviewsCount,
    };
};
