import React from 'react';
import { ICartRepository } from '@silkpwa/magento/api/cart-repository/repository';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import { BaseProduct, IBaseProductConfigProps } from '@silkpwa/module/react-component/product-config/base-product';
import { IBundleOptionData, IBundleSelectionData } from 'chefworks-theme/src/ui/component/product-configurator/product-config/bundle-config/bundle-option';
import { checkBundleSelections } from '../util';
import { RenderConfigurator } from '../render-configurator';

interface ICalculcatedPrices {
    calculatedPrice: number;
    calculatedOriginalPrice: number;
}

export class BundleProductConfig extends BaseProduct {
    constructor(props: Readonly<IBaseProductConfigProps>) {
        super(props);

        const { product } = this.props;

        this.state = {
            ...this.state,
            selectionsQty: {},
            calculatedPrice: 0,
            calculatedOriginalPrice: 0,
            dynamicSku: product.sku,
        };

        this.setSelections = this.setSelections.bind(this);
        this.areBundleOptionsSelected = this.areBundleOptionsSelected.bind(this);
    }

    get selectionsQty(): IProductConfigData['selections'] {
        const { selectionsQty } = this.state;
        return selectionsQty;
    }

    setSelections(key: string, value: string, qty?: string) {
        const { selections } = this;
        const { selectionsQty } = this;

        selections[key] = value;

        if (typeof qty !== 'undefined') {
            selectionsQty[key] = qty;
        }

        const calculatedPrices = this.getCalculatedPriceBasedOnSelections(
            selections, selectionsQty,
        );
        const calculatedPrice = calculatedPrices
            ? calculatedPrices.calculatedPrice : 0;
        const calculatedOriginalPrice = calculatedPrices
            ? calculatedPrices.calculatedOriginalPrice : 0;

        const dynamicSku = this.generateDynamicSku(selections);

        this.setState({
            selections,
            selectionsQty,
            calculatedPrice,
            calculatedOriginalPrice,
            dynamicSku,
        });
    }

    generateDynamicSku(selections: IProductConfigData['selections']): string {
        const { product } = this.props;
        let dynamicSku = product.sku;

        product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
            if (selections[bundleOption.id] === undefined) {
                return;
            }

            const selectedId = selections[bundleOption.id];
            bundleOption.selections.forEach(((selection) => {
                if (selection.selection_id === selectedId) {
                    dynamicSku += `-${selection.sku}`;
                }
            }));
        });

        return dynamicSku;
    }

    areBundleOptionsSelected(selections?: IProductConfigData['selections']): boolean {
        let result = true;
        const { product } = this.props;
        const bundleSelections = selections || this.selections;

        product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
            if (
                bundleOption.required === '1' &&
                bundleSelections[bundleOption.id] === undefined
            ) {
                result = false;
            }
        });
        return result;
    }

    getCalculatedPriceBasedOnSelections(
        selections: IProductConfigData['selections'],
        selectionsQty: IProductConfigData['selections'],
    ): ICalculcatedPrices | null {
        if (this.areBundleOptionsSelected(selections)) {
            return this.calculateTotalPriceFromSelections(selections, selectionsQty);
        }

        return null;
    }

    calculateTotalPriceFromSelections(
        selections: IProductConfigData['selections'],
        selectionsQty: IProductConfigData['selections'],
    ): ICalculcatedPrices {
        const { product } = this.props;
        let result = 0;
        let originalResult = 0;
        product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
            const bundleOptionId = parseInt(bundleOption.id, 10);
            if (selections[bundleOptionId] !== undefined) {
                bundleOption.selections.forEach((selection: IProductConfigData['selections']) => {
                    if (selection.selection_id === selections[bundleOptionId]) {
                        const quantity = selectionsQty[bundleOptionId]
                            ? parseInt(selectionsQty[bundleOptionId], 10) : 1;
                        result += parseFloat(selection.price) * quantity;
                        originalResult += parseFloat(selection.original_price) * quantity;
                    }
                });
            }
        });

        return {
            calculatedPrice: result,
            calculatedOriginalPrice: originalResult,
        };
    }


    protected handleEditItem() {
        const item = this.cartItem;
        /* eslint-disable camelcase */
        const { selections, selections_qty } = item;

        Object.keys(selections).forEach((optionId) => {
            this.setSelections(
                optionId.toString(),
                selections[optionId].toString(),
                selections_qty && selections_qty[optionId]
                    ? selections_qty[optionId].toString() : '1',
            );
        });
        /* eslint-enable camelcase */

        this.setState({
            quantity: item.qty,
        });
    }

    protected handleNewItem() {
        const { product } = this.props;
        product.bundledProducts.forEach((option: IBundleOptionData) => {
            option.selections.forEach((selection: IBundleSelectionData) => {
                if (selection.is_default === '1') {
                    this.setSelections(option.id, selection.selection_id);
                }
            });
        });
    }

    private collectBundleSelectionsQty() {
        const { product } = this.props;
        const bundleSelectionsQty: { [key: string]: any } = {};
        product.bundledProducts.forEach((option: IBundleOptionData) => {
            const selected = this.selections[option.id];
            if (selected) {
                option.selections.forEach((selection: IBundleSelectionData) => {
                    if (selection.selection_id === selected) {
                        // If user cannot change quantity, assign the default quantity
                        if (selection.can_change_qty === '0') {
                            bundleSelectionsQty[option.id] = selection.default_qty;
                        } else {
                            const { selectionsQty } = this;
                            bundleSelectionsQty[option.id] = selectionsQty[option.id];
                        }
                    }
                });
            }
        });

        return bundleSelectionsQty;
    }

    protected async addItemToCart(repository: ICartRepository) {
        const { productId } = this.props;
        if (!productId) {
            throw new Error('No Product Id assigned');
        }

        const bundleSelectionsQty = this.collectBundleSelectionsQty();

        await repository.addBundleProduct(
            productId,
            this.selections,
            this.quantity.current,
            bundleSelectionsQty,
        );
    }

    async addToCart(repository: ICartRepository) {
        const {
            itemId,
            close,
            t,
            enqueue,
        } = this.props;

        try {
            if (!checkBundleSelections(this)) {
                enqueue({
                    type: 'primary',
                    message: t('Not all required options were selected'),
                    time: 5000,
                });
                return;
            }
            if (itemId && this.quantity.current === 0) {
                await this.removeItem(repository);
            } else if (itemId) {
                await this.updateItem(repository);
            } else {
                await this.addNewItem(repository);
            }

            if (close) {
                close();
            }
        } finally {
            this._adding = false;
        }
    }

    render() {
        const { children } = this.props;
        const {
            calculatedPrice, calculatedOriginalPrice, dynamicSku, validation, reviews,
        } = this.state;
        this.data = {
            ...this.renderData(),
            type: 'BundleConfig',
            dynamicSku,
            setSelections: this.setSelections,
            calculatedPrice,
            calculatedOriginalPrice,
            areBundleOptionsSelected: this.areBundleOptionsSelected,
            selectionsQty: this.selectionsQty,
            validation,
            reviews,
        };

        return (
            <RenderConfigurator data={this.data}>
                {children}
            </RenderConfigurator>
        );
    }
}
