import React from 'react';
import { usePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import styles from './styles.css';

interface IOriginalPriceProps {
    product: any; // TODO: update type
    calculatedOriginalPrice: number | undefined;
}

export const OriginalPrice: React.FC<IOriginalPriceProps> = ({
    product,
    calculatedOriginalPrice,
}) => {
    const roundPrice = usePriceRounder();
    const formatPrice = usePriceFormatter();
    const originalPrice = roundPrice(calculatedOriginalPrice || product.unformatted.originalPrice);
    const price = roundPrice(product.unformatted.price);
    if (!price || originalPrice <= price) {
        return null;
    }

    return (
        <div
            data-test="original-price"
            data-original-price={originalPrice}
            data-price={price}
            className={styles.originalPrice}
        >
            {formatPrice(originalPrice)}
        </div>
    );
};
