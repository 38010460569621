import React from 'react';
import { Disclaimer } from 'ui/component/portal/disclaimer/disclaimer';
import styles from './style.css';

import ChefworksPortalDataPortalProfileInterface = Magento.Definitions.ChefworksPortalDataPortalProfileInterface;


interface IPortalDataProps {
    area: string;
    data: ChefworksPortalDataPortalProfileInterface|null;
    isCustomerLoggedIn: boolean;
}

/* eslint-disable react/no-danger */
export const PortalData: React.FC<IPortalDataProps> = ({ area, data, isCustomerLoggedIn }) => {
    let message = '';
    let messageClass = '';
    let testDataName = '';
    if (data) {
        if (area === 'aboveLoginMessage' && data.above_login_details) {
            message = data.above_login_details;
            messageClass = styles.aboveLoginMessage;
            testDataName = 'portal_above_login_test';
        }
        if (area === 'belowLoginMessage' && data.below_login_details) {
            message = data.below_login_details;
            messageClass = styles.belowLoginMessage;
            testDataName = 'portal_below_login_test';
        }
        if (area === 'footerNavigation' && data.footer_navigation && isCustomerLoggedIn) {
            message = data.footer_navigation;
            messageClass = styles.footerNavigation;
            testDataName = 'portal_footer_navigation_test';
        }
        if (area === 'disclaimer' && data.disclaimer) {
            return (
                <Disclaimer
                    message={data.disclaimer}
                />
            );
        }

        if (area === 'headerLogo' && data.logo) {
            message = data.logo;
            messageClass = styles.headerLogo;
            testDataName = 'portal_header_logo_test';
            return (
                <span>
                    {data.logo ? (
                        <span className={messageClass}>
                            <img src={data.logo} alt={data.name} />
                        </span>
                    ) : ''}
                </span>
            );
        }
    }
    return (
        <span>
            {message ? (
                <span className={messageClass}>
                    <span
                        data-test={testDataName}
                        dangerouslySetInnerHTML={{
                            __html: message,
                        }}
                    />
                </span>
            ) : ''}
        </span>
    );
};
/* eslint-enable */
