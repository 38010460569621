import {
    ModuleCreator,
    Duck,
    Dispatch,
    GetState,
} from '@silkpwa/redux';
import { IAccountRepository } from '@silkpwa/magento/api/account-repository';
import { IUserInfo } from '@silkpwa/magento/api/account-repository/repository';
import {
    IOverrideCategoriesInfo,
    getOverrideCategoriesInfo,
} from 'chefworks-theme/src/ui/util/override-categories/get-info-from-account';
import {
    setIsOverrideCategories,
    clearOverrideCategoriesInfo,
} from 'chefworks-theme/src/ui/util/override-categories/id-param-resolver';
import { IPersist } from '../persist';
import { IRouter } from '../router';
import { AccountState, AccountModuleState } from './account-interfaces';

import IFoundPortalCustomers = Magento.Definitions.ChefworksPortalDataFoundPortalCustomersInterface;

const Account = new ModuleCreator({
    inject: ['router', 'accountRepository', 'persist', 'ecommerceCategory', 'overrideCategory'],
    create(
        router: IRouter,
        accountRepository: IAccountRepository,
        persist: IPersist,
        ecommerceCategory,
        overrideCategory,
    ) {
        const initialState: AccountModuleState = {
            account: {
                initialState: true,
                isLoggedIn: false,
                info: {} as IUserInfo,
            },
            redirectUri: '/',
        };

        return new Duck({
            name: 'account',
            construct() {
                persist.persistPath([this.slice], 'keyed');
            },
            actionTypes: [
                'USER_LOGGED_IN',
                'USER_LOGGED_OUT',
                'ENTER_LOG_IN_PAGE',
                'REFRESHED_INFO',
                'USER_ACTUALLY_LOGGED_OUT',
                'USER_ACTUALLY_LOGGED_IN',
            ],
            reducer(state = initialState, action) {
                switch (action.type) {
                    case this.actionTypes.USER_LOGGED_IN:
                        return {
                            ...state,
                            account: {
                                info: action.info,
                                isLoggedIn: true,
                            },
                        };
                    case this.actionTypes.USER_LOGGED_OUT:
                        return {
                            ...state,
                            account: {
                                isLoggedIn: false,
                                info: {},
                            },
                        };
                    case this.actionTypes.ENTER_LOG_IN_PAGE:
                        return {
                            ...state,
                            redirectUri: action.redirectUri,
                        };
                    default:
                        return state;
                }
            },
            actions: {
                logIn(
                    propertyCode: string,
                    usernameWithoutCode: string,
                    password: string,
                    redirectAfterLoggedIn = true,
                ) {
                    let username = usernameWithoutCode.trim();
                    if (propertyCode) {
                        const separator = '#';
                        username = (propertyCode.trim() + separator + usernameWithoutCode.trim());
                    }
                    return async (dispatch: Dispatch, getState: GetState) => {
                        const info = await accountRepository.logIn({
                            username, password,
                        });

                        dispatch({
                            type: this.actionTypes.USER_LOGGED_IN,
                            info,
                        });

                        dispatch({
                            type: this.actionTypes.USER_ACTUALLY_LOGGED_IN,
                            info,
                        });

                        /**
                         * Resolve `Override Categories` info once user is logged in. Using `reset` flag before
                         * setting an actual `Override Categories` info from account.
                         */
                        const isOverrideCategories: boolean = this.actions.getIsOverrideCategories(info);
                        this.actions.resolveOverrideCategoriesInfo(info, true);

                        /**
                         * Update the category menu once user (including portal user) is logged in.
                         */
                        if (isOverrideCategories) {
                            await overrideCategory.fetchCategoryTree(dispatch);
                        } else {
                            await ecommerceCategory.fetchCategoryTree(dispatch);
                        }

                        const { redirectUri } = this.select(getState());
                        const { extensionAttributes } = info;
                        const isPortalRedirect = Boolean(extensionAttributes?.portal_redirect_after_login);
                        const code = info.extensionAttributes?.portal_data?.code.toLowerCase() ?? '';
                        const redirectToDefaultUrl = isOverrideCategories && code.length
                            ? `${code}/default-category`
                            : '/default-category';
                        if (redirectAfterLoggedIn) {
                            const redirectToUri = isPortalRedirect ? redirectToDefaultUrl : redirectUri;
                            dispatch(router.actions.navigate(redirectToUri));
                        } else {
                            // Refresh the blocks after logged in while keeping the current state
                            const currentLocation = router.selectors.location(getState());
                            dispatch(router.actions.navigate(currentLocation));
                        }
                        return info;
                    };
                },
                findPortalCustomer(
                    usernameWithoutCode: string,
                ): (_1: Dispatch, _2: GetState) => Promise<IFoundPortalCustomers> {
                    const username = usernameWithoutCode.trim();
                    return async (
                        _1: Dispatch,
                        _2: GetState,
                    ): Promise<IFoundPortalCustomers> => accountRepository.findPortalCustomer(
                        { username },
                    );
                },
                async refreshAccountInfo(dispatch: Dispatch, getState: GetState) {
                    try {
                        const info = await accountRepository.getAccountInfo();
                        dispatch({
                            type: this.actionTypes.USER_LOGGED_IN,
                            info,
                        });
                        /**
                         * If user is logged in on refresh account info - we have to ensure `Override Categories`
                         * data is resolved.
                         */
                        this.actions.resolveOverrideCategoriesInfo(info, false);
                    } catch (e) {
                        dispatch({
                            type: this.actionTypes.USER_LOGGED_OUT,
                        });
                        /**
                         * Custom method for Portal Assigned Products Logic - redirect the logged-out user from the
                         * 'default-category' page to the Home Page, condition:
                         * - if current page is the 'default-category' ('All Products');
                         * - otherwise, skip redirect.
                         */
                        this.actions.redirectFromDefaultCategory(dispatch, getState, true);
                        /**
                         * Clear `Override Categories` data if user appears to be not logged in.
                         */
                        clearOverrideCategoriesInfo();
                    }

                    dispatch({
                        type: this.actionTypes.REFRESHED_INFO,
                    });
                },
                async logOut(dispatch: Dispatch, getState: GetState) {
                    await accountRepository.logOut();
                    dispatch({
                        type: this.actionTypes.USER_LOGGED_OUT,
                    });

                    /**
                     * Custom method for Portal Assigned Products Logic - redirect the logged-out user from the
                     * 'default-category' page to the Home Page, condition:
                     * - if current page is the 'default-category' ('All Products');
                     * - otherwise, use current location for redirect.
                     */
                    this.actions.redirectFromDefaultCategory(dispatch, getState, false);
                    /**
                     * Clear `Override Categories` data if user has logged out.
                     */
                    clearOverrideCategoriesInfo();

                    dispatch({
                        type: this.actionTypes.USER_ACTUALLY_LOGGED_OUT,
                    });
                    /**
                     * Also, we have to update menu info once the customer has logged out in order to hide
                     * '/default-category' menu link which is added on the BE.
                     */
                    await ecommerceCategory.fetchCategoryTree(dispatch);
                },
                /**
                 * If current user has logged out and if it is the '/default-category' category path -
                 * we have to not allow to access this category for the guest user, this category will not
                 * be visible in the menu.
                 */
                redirectFromDefaultCategory(dispatch: Dispatch, getState: GetState, isRefreshInfo: boolean) {
                    const location = router.selectors.location(getState());
                    const logOutLocation = location;
                    const isDefaultCategory = location.pathname === '/default-category';
                    if (isDefaultCategory) {
                        logOutLocation.pathname = '/';
                    }

                    if (!isRefreshInfo || (isRefreshInfo && isDefaultCategory)) {
                        dispatch(router.actions.navigate(logOutLocation));
                    }
                },
                /**
                 * Resole `Is Override Categories` flag
                 */
                getIsOverrideCategories(info: IUserInfo): boolean {
                    const accountInfo: AccountState = {
                        isLoggedIn: true,
                        info,
                    };
                    const overrideCategoriesInfo: IOverrideCategoriesInfo = getOverrideCategoriesInfo(accountInfo);
                    const { isOverrideCategories } = overrideCategoriesInfo;

                    return isOverrideCategories;
                },
                /**
                 * Resole `Override Categories` Info in session storage
                 */
                resolveOverrideCategoriesInfo(info: IUserInfo, reset: boolean): void {
                    const isOverrideCategories: boolean = this.actions.getIsOverrideCategories(info);

                    /**
                     * Reset the `Override Categories` Info before setting up the actual data from the account info
                     * if there is flag provided.
                     */
                    if (reset) {
                        clearOverrideCategoriesInfo();
                    }

                    setIsOverrideCategories(isOverrideCategories);
                },
                enterLogInPage(params) {
                    const redirectUri = params ? params.redirectUri : '/';

                    return {
                        type: this.actionTypes.ENTER_LOG_IN_PAGE,
                        redirectUri,
                    };
                },
            },
            selectors: {
                getAccount(state) {
                    return this.select(state).account;
                },
            },
            initialize(store) {
                router.handleOnce(async (route) => {
                    await store.dispatch(this.actions.refreshAccountInfo);
                    route.done();
                });

                router.addHandler('login', (route) => {
                    route.progress(1);
                    const { params }: any = route.resource;
                    const redirectUri = params ? params.redirectUri : '/customer/account';
                    if (this.selectors.getAccount(store.getState()).isLoggedIn) {
                        store.dispatch(router.actions.navigate(redirectUri));
                    } else {
                        store.dispatch(this.actions.enterLogInPage(params));
                    }
                });
            },
        });
    },
});

export { Account, AccountModuleState };
