import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoadingImage } from 'ui/component/loading-image';
import { GoogleRecaptchaWidget } from '@silkpwa/module/google-recaptcha';
import {
    IEmailSubscription,
    EmailSubscription,
} from '@silkpwa/module/react-component/email-subscription';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useDotDigitalConfig } from '@silkpwa/module/dotdigital-config';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export interface IHandleError {
    consentError: string;
    setConsentError: (error: string) => void;
}

export interface ISubmitNewsletterProps {
    event: React.FormEvent;
    handleError: IHandleError;
    recaptchaRef: React.MutableRefObject<ReCAPTCHA|undefined>;
}

export const DotDigitalFieldset = ({ handleError }: { handleError: IHandleError}) => {
    const t = usePhraseTranslater();
    const handleConsent = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            handleError.setConsentError('');
        }
    };
    const dotDigitalConfig = useDotDigitalConfig();
    const isConsent: boolean = dotDigitalConfig.newsletter_form_enabled &&
        dotDigitalConfig.newsletter_form_need_consent;
    const consentText = dotDigitalConfig.newsletter_form_consent_text ??
        t('I consent to receive newsletter and promotional emails.');

    return isConsent ? (
        <fieldset className={classes(styles.fieldset, styles.consent)}>
            <div className={styles.field}>
                <label className={styles.label} htmlFor="consent">
                    <span>{t(consentText)}</span>
                    <div className={styles.control}>
                        <input
                            type="checkbox"
                            name="consent"
                            className={styles.checkbox}
                            id="consent"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleConsent(event)}
                        />
                    </div>
                </label>
                {handleError.consentError && (
                    <div className={styles.consentError} id="consentError">
                        {handleError.consentError}
                    </div>
                )}
            </div>
        </fieldset>
    ) : null;
};

export const submitNewsletter = ({ event, handleError, recaptchaRef }: ISubmitNewsletterProps): void => {
    event.preventDefault();
    const checkbox = document.getElementById('consent') as HTMLInputElement;
    if (checkbox && !checkbox.checked) {
        handleError.setConsentError('This is required');
        return;
    }

    if (recaptchaRef.current) {
        recaptchaRef.current.execute();
    }
};

const ConnectForm = ({ email, handleError }: { email: IEmailSubscription; handleError: IHandleError}) => {
    const t = usePhraseTranslater();
    const recaptchaRef: React.MutableRefObject<ReCAPTCHA|undefined> = useRef<ReCAPTCHA>();
    return (
        <div>
            <div className={styles.emailBox}>
                <form
                    onSubmit={
                        (event: React.FormEvent) => submitNewsletter({
                            event,
                            handleError,
                            recaptchaRef,
                        })
                    }
                >
                    <input
                        type="text"
                        id="connectIdentity"
                        placeholder={t('Enter your email')}
                        value={email.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => email.setEmail(event)}
                    />
                    <input type="submit" value={t('connect')} />
                    <DotDigitalFieldset handleError={handleError} />
                    <GoogleRecaptchaWidget
                        form="newsletter"
                        position="inline"
                        size="invisible"
                        submit={email.sendEmail}
                        recaptchaRef={recaptchaRef}
                    />
                </form>
            </div>
            {email.error && (
                <div className={styles.error}>
                    {email.error}
                </div>
            )}
            <div className={styles.loading}>
                {email.isLoading && (
                    <LoadingImage />
                )}
            </div>
        </div>
    );
};

export const EmailConnectWidget = (_: any) => {
    const t = usePhraseTranslater();
    const [consentError, setConsentError] = useState('');
    const handleError = {
        consentError,
        setConsentError,
    };

    return (
        <EmailSubscription>
            {(email: IEmailSubscription) => (
                email.isSubscribed
                    ? (<div className={styles.feedback}>{t('Thanks for subscribing')}</div>)
                    : (<ConnectForm email={email} handleError={handleError} />)
            )}
        </EmailSubscription>
    );
};
