/* eslint-disable class-methods-use-this */
import { ITextOption } from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import {
    transformV2Placements,
    compileBooleanCheck,
    addSourceMapping,
    produceOutput,
    getSourceMapping,
    optionsArray,
} from './util';

import IRuleTextEmbLine = Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;
import IProductCustomOption = Magento.Definitions.SilkRestappDataProductOptionCustomOptionInterface;

export class TextTransformer {
    private compileSizes(embroideryType, sizes) {
        const compileComputeMatch = (size) => {
            const code = `(function(embroideryType, text) {
                return [${size.size}, (${size.computeMatch})];
            })`;

            // Not an issue, the source data can only be set by a CW
            // employee.
            // eslint-disable-next-line no-eval
            return eval(code);
        };
        const sizesArr = sizes ? JSON.parse(sizes) : [];

        const sizeComps = sizesArr.map(compileComputeMatch);

        return (lines) => {
            const sizeValues = sizeComps.map(s => s(embroideryType, lines));

            const sorted = sizeValues.sort((a, b) => {
                if (a[1] > b[1]) {
                    return -1;
                }
                if (a[1] === b[1]) {
                    return 0;
                }

                return 1;
            });

            return sorted[0][0];
        };
    }

    fromApiData(productInfo, option, data): ITextOption|undefined {
        if (data.embroidery_placements.for_type.options.text.length === 0) return undefined;
        const fontOptions = data.embroidery_placements.global.fonts.map(f => ({
            frontendValue: f.name,
            backendValue: f.backend_name ? f.backend_name : f.name,
            getSize: this.compileSizes(data.embroidery_type, f.sizes),
        }));

        const colorOptions = data.embroidery_placements.global.colors.map(c => ({
            frontendValue: c.hex,
            backendValue: c.name,
            border: c.border,
        }));

        const lineOptions: IProductCustomOption[] = option.custom_options.filter(x => x.option_code.indexOf('embroidery_line') === 0);

        const rules: IRuleTextEmbLine[] = data.text_embroidery_rules_per_line;
        if (rules.length > 0) {
            rules.forEach((lineRule: IRuleTextEmbLine): void => {
                const lineIndex: number = lineRule.line_number - 1;
                const lineOption: IProductCustomOption = lineOptions[lineIndex];
                if (!lineOption || lineRule.character_limit <= 0) {
                    return;
                }

                lineOptions[lineIndex].max_characters = lineRule.character_limit;
            });
        }

        let embTextLines: IRuleTextEmbLine[];

        if (rules && rules.length) {
            embTextLines = rules;
        } else {
            embTextLines = lineOptions.map((lineOption: IProductCustomOption, index: number) => ({
                character_limit: Number(lineOption.max_characters),
                emb_rule_id: 0,
                id: 0,
                is_required: Number(lineOption.is_required),
                label: '',
                line_number: index + 1,
            }));
        }

        const priceForLine = lineOptions.map(x => x.price);

        const placementOptions = transformV2Placements(
            data.embroidery_placements.for_type.options.text,
        );

        return addSourceMapping(productInfo, option, {
            type: 'text',
            label: option.name,
            minLines: 1,
            maxLines: embTextLines.length,
            lines: [],
            embTextLines,
            font: '',
            color: '',
            placement: '',
            basePrice: option.price,
            fontOptions,
            colorOptions,
            placementOptions,
            priceForLine,
            textAllowed: compileBooleanCheck(
                'text',
                data.embroidery_placements.global.text_allowed,
            ),
            maxCharacters: lineOptions.map(x => Number(x.max_characters)),
        });
    }

    toApiData(option, _, productInfo) {
        const { optionLookup } = getSourceMapping(option);

        const options = {
            [optionLookup.embroidered_thread_style]: option.font,
            [optionLookup.embroidered_thread_color]: option.color,
            [optionLookup.embroidery_placement]: option.placement,
        };

        option.lines.forEach((line, i) => {
            const index = i + 1;
            options[optionLookup[`embroidery_line${index}`]] = line;
        });

        return produceOutput(option, options, productInfo);
    }

    private restoreLines(data) {
        const result = {
            color: undefined,
            font: undefined,
            placement: undefined,
            lines: [],
        };

        const lines = {};

        data.options.forEach((o) => {
            const matches = o.option_code.match(/embroidery_line(\d+)/);
            if (matches) {
                lines[matches[1]] = o.option_value;
            }

            if (o.option_code === 'embroidered_thread_color') {
                result.color = o.option_value;
            }

            if (o.option_code === 'embroidered_thread_style') {
                result.font = o.option_value;
            }

            if (o.option_code === 'embroidery_placement') {
                result.placement = o.option_value || '';
            }
        });

        result.lines = optionsArray(lines);

        return result;
    }

    restoreData(option, data) {
        return {
            ...option,
            ...this.restoreLines(data),
        };
    }
}
