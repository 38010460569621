import React from 'react';
import { ViewPort } from '@silkpwa/module/react-component/viewport';
import { CategoryPages } from 'ui/component/warmup/category-pages';
import { PromotionsBanner, AddPromotionsState } from 'ui/component/promotions-banner';
import { SalesRepresentativeBanner } from 'ui/component/sales-representative';
import { EmailSubscriptionPopup } from 'ui/component/email-subscription-popup';
import { Header } from './header';
import { Content } from './content';
import { Footer } from './footer';

export const Layout = () => (
    <AddPromotionsState>
        <PromotionsBanner />
        <SalesRepresentativeBanner />
        <EmailSubscriptionPopup />
        <ViewPort>
            <Header />
            <Content />
            <Footer />
        </ViewPort>
        <CategoryPages />
    </AddPromotionsState>
);
