import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { StockStatus } from './stock-status';
import styles from './styles.css';

interface ISKUProps {
    product: any; // TODO: add correct type
    dynamicSku: string | undefined;
}

export const SKU: React.FC<ISKUProps> = ({
    product,
    dynamicSku,
}) => {
    const t = usePhraseTranslater();
    const sku = dynamicSku || product.sku;
    return (
        <div data-test="sku" data-sku={sku} className={styles.sku}>
            {t('Item:')}
            {' '}
            <span className="weglot-exclude">{sku}</span>
            <StockStatus
                product={product}
                parentClass={styles.skuStockStatusContainer}
                type=""
            />
        </div>
    );
};
