import React from 'react';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { connectCompare } from '@silkpwa/module/react-component/connect-compare';
import { connectRouter } from '@silkpwa/module/react-component/connect-router';
import { useIsPunchoutInspectOperation, useStoreMode } from '@silkpwa/module/store-mode';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { connectCart } from 'ui/util/connect-cart';
import { AccountIcon } from 'ui/component/header/icons/account-icon';
import { CartIcon } from 'ui/component/header/icons/cart-icon';
import { CompareIcon } from 'ui/component/header/icons/compare-icon';
import { MiniCart } from 'ui/component/minicart';
import { AccountDropdown } from 'ui/component/account-dropdown';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IconsProps {
    compareItems;
    account: AccountState;
    accountActions;
    cartItems;
    cartSummaryData;
    currentLocation;
    cartFreeShippingProgressBar;
}

const Icons: React.FC<IconsProps> = ({
    compareItems,
    account,
    accountActions,
    cartItems,
    cartSummaryData,
    currentLocation,
    cartFreeShippingProgressBar,
}) => {
    const mode = useStoreMode();
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const showCompareIcon = false;

    return isPunchoutInspectOperation ? null : (
        <div
            id="customer-account-area"
            data-customer-logged-in={account.isLoggedIn}
            className={classes(styles.icons, 'normal-header-icons')}
            data-test="header-top-icons"
            data-test-store-mode={mode.storeMode}
            data-test-username={account.info.username}
        >
            {showCompareIcon && mode.isRetail ? (
                <CompareIcon compareItemCount={compareItems.length} top="18px" />
            ) : null }
            {mode.isRetail ? (
                <AccountIcon account={account} currentLocation={currentLocation}>
                    {open => (
                        <div className={styles.accountDropdown}>
                            <AccountDropdown
                                open={open}
                                accountActions={accountActions}
                                className={styles.accountDropdownPosition}
                            />
                        </div>
                    )}
                </AccountIcon>
            ) : null }
            <CartIcon cartItems={cartItems} top="25px" right="-15px" testAttribute="desktop-cart-icon">
                {({ open, close }) => (
                    <MiniCart
                        cartItems={cartItems}
                        cartSummaryData={cartSummaryData}
                        open={open}
                        close={close}
                        cartFreeShippingProgressBar={cartFreeShippingProgressBar}
                    />
                    )}
            </CartIcon>
        </div>
    );
};

const ConnectedIcons = connectRouter(connectCart(connectAccount(connectCompare(Icons))));

export { ConnectedIcons as Icons };
