import React, { ReactNode } from 'react';
import { ICartRepository } from '@silkpwa/magento/api/cart-repository/repository';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';
import { PhraseTranslater } from '@silkpwa/module/i18n';
import { CART_ITEM_ADDED } from '@silkpwa/module/ecommerce-cart/events';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import { fixValue } from '../util';

import SilkRestappDataCartCustomOptionInterface = Magento.Definitions.SilkRestappDataCartCustomOptionInterface;

/* eslint-disable camelcase */
export interface ICartItem {
    item_id: number;
    qty: number;
    product_type: string;
    product_id: number;
    selections: { [key: string]: any };
    selections_qty?: { [key: string]: string | number };
    custom_options?: SilkRestappDataCartCustomOptionInterface[];
}
/* eslint-enable camelcase */

export interface IBaseProductConfigProps {
    productId?: number;
    product: any; // TODO: add specific type
    state: any; // TODO: add specific type
    cart: ICartItem[];
    itemId: number;
    getProduct: (state: any, id: number) => any; // TODO: add specific product type for => any
    loadProduct: (productId: number, parentId: number, parentCartItem?: ICartItem) => void;
    appEventBus: AppEventBus;
    addProduct: any; // TODO: add specific type
    close: () => void;
    enqueue: any; // TODO: add specific type
    t: PhraseTranslater['translate'];
    initiallyFetch: boolean;
    fallback: ReactNode;
}

export class BaseProduct extends React.Component<IBaseProductConfigProps, any> {
    protected _adding: boolean;

    protected data?: IProductConfigData;

    private _simpleProductId: number;

    constructor(props: Readonly<IBaseProductConfigProps>) {
        super(props);

        this.state = {
            quantity: 1,
            selections: {},
            validation: false,
            reviews: {
                sort: 'date-desc',
            },
        };

        this._adding = false;

        this.handleCommit = this.handleCommit.bind(this);
        this.addToCart = this.addToCart.bind(this);

        this._simpleProductId = 0;
    }

    componentDidMount() {
        this.handleItemInitialization();
    }

    protected get simpleProductId(): number {
        return this._simpleProductId;
    }

    protected set simpleProductId(value: number) {
        this._simpleProductId = value;
    }

    get selections(): IProductConfigData['selections'] {
        const { selections } = this.state;
        return selections;
    }

    get productImages(): IProductConfigData['productImages'] {
        const { state } = this.props;
        return this.getProductImages(state);
    }

    get simpleProduct() {
        const { state } = this.props;
        return this.getSimpleProduct(state);
    }

    get cartItem() {
        const { cart, itemId } = this.props;
        return cart.filter(x => x.item_id === itemId)[0];
    }

    get imageProductId(): number {
        const { product } = this.props;
        return (
            this.simpleProductId ||
            product.id
        );
    }

    get validation(): { current: boolean; set: (v: boolean) => void; valueOf(): boolean } {
        const { validation } = this.state;
        return {
            valueOf(): boolean {
                return false;
            },
            current: validation,
            set: (v) => {
                this.setState({ validation: v });
            },
        };
    }

    get reviews(): { sort: string; setSort: (v: string) => void; valueOf(): boolean } {
        const { reviews } = this.state;
        return {
            valueOf(): boolean {
                return false;
            },
            sort: reviews.sort,
            setSort: (v) => {
                this.setState({
                    reviews: {
                        sort: v,
                    },
                });
            },
        };
    }

    get quantity(): IProductConfigData['quantity'] {
        const { quantity, qtyIncr } = this.processQuantity();

        return {
            current: quantity,
            increment: () => this.updateQty(qty => qty + qtyIncr),
            decrement: () => this.updateQty(qty => qty - qtyIncr),
            set: v => this.updateQty(() => v),
        };
    }

    private getSimpleProduct(state) {
        const { getProduct, product } = this.props;
        return getProduct(
            state,
            this.simpleProductId || product.id,
        );
    }


    private getProductImages(state): IProductConfigData['productImages'] {
        const { getProduct } = this.props;
        const product = getProduct(state, this.imageProductId);

        if (product.id === -1) {
            return {
                type: 'loading',
            };
        }

        return {
            type: 'images',
            images: product.images,
        };
    }

    protected processQuantity() {
        const { product } = this.props;
        const { simpleProduct } = this;
        const qi = (p: any) => (p || {}).qtyIncrements;
        const qtyIncrements = qi(simpleProduct) || qi(product) || 0;
        let { quantity } = this.state as { quantity: number };

        const qtyIncr = Math.max(1, qtyIncrements);
        if (qtyIncr > 1) {
            quantity = qtyIncr * Math.ceil(quantity / qtyIncr);
        }
        return {
            quantity,
            qtyIncr,
        };
    }

    handleCommit() {
        if (this._adding) return;
        this._adding = true;
        const {
            addProduct,
        } = this.props;
        addProduct({
            addToCart: this.addToCart,
        });
    }

    protected handleEditItem() {
        throw new Error(`Implementation for ${typeof this}.handleEditItem is required`);
    }

    protected handleNewItem() {
        throw new Error(`Implementation for ${typeof this}.handleNewItem is required`);
    }

    private handleItemInitialization() {
        const { itemId } = this.props;
        if (itemId) {
            this.handleEditItem();
        } else {
            this.handleNewItem();
        }
    }

    private updateQty(update: (v: number) => number) {
        this.setState(s => ({
            quantity: fixValue(
                update(s.quantity),
                s.quantity,
                0,
            ),
        }));
    }

    async addToCart(repository: ICartRepository) {
        throw new Error(`Implementation for ${typeof this}.addToCart(${typeof repository}) is required`);
    }

    protected async updateItem(repository: ICartRepository) {
        const { itemId, appEventBus, product } = this.props;

        const item = this.cartItem;

        await repository.removeItem(itemId);
        await this.addItemToCart(repository);

        appEventBus.publish(
            'cart.item.updated',
            item,
            product,
            this.simpleProduct,
            this.quantity.current,
        );
    }

    protected async addItemToCart(repository: ICartRepository) {
        throw new Error(`Implementation for ${typeof this}.addItemToCart(${typeof repository}) is required`);
    }

    protected async addNewItem(repository: ICartRepository) {
        const { appEventBus, product } = this.props;

        await this.addItemToCart(repository);

        appEventBus.publish(
            CART_ITEM_ADDED,
            product,
            this.simpleProduct,
            this.quantity.current,
        );
    }

    protected async removeItem(repository: ICartRepository) {
        const { itemId, appEventBus } = this.props;
        const item = this.cartItem;
        await repository.removeItem(itemId);
        appEventBus.publish('cart.item.removed', item);
    }


    protected renderData(): IProductConfigData {
        const { itemId, product } = this.props;
        return {
            type: 'BaseConfig',
            selections: this.selections,
            quantity: this.quantity,
            isStored: !!itemId,
            handleCommit: this.handleCommit,
            configItemId: itemId,
            product,
            simpleProduct: this.simpleProduct,
            productImages: this.productImages,
            validation: this.validation,
            reviews: this.reviews,
        };
    }
}
