import { gql, TypedDocumentNode } from '@apollo/client';
import { CART_FIELDS } from './fragments';

export const GET_PAYMENT_METHODS: TypedDocumentNode = gql`
    query GET_PAYMENT_METHODS(
        $cartId: String!
    ){
        cart(
            cart_id: $cartId
        ) {
            available_payment_methods {
                code
                title
            }
        }
    }
`;

export const SET_PAYMENT_METHOD: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation SET_PAYMENT_METHOD(
        $cartId: String!
        $paymentMethodCode: String!
        $purchaseOrderNumber: String
        $cardType: String
        $cardHolderName: String
        $cardNumber: String
        $expiryMonth: String
        $expiryYear: String
        $cvv: String
        $isActivePaymentTokenEnabler: String
    ){
        setPaymentMethodOnCart(input: {
            cart_id: $cartId
            payment_method: {
                code: $paymentMethodCode
                purchase_order_number: $purchaseOrderNumber
                additional_data: {
                    cc_type: $cardType
                    cc_holder_name: $cardHolderName
                    cc_number: $cardNumber
                    cc_exp_month: $expiryMonth
                    cc_exp_year: $expiryYear
                    cc_cid: $cvv
                    is_active_payment_token_enabler: $isActivePaymentTokenEnabler
                }
            }
        }) {
            cart {
                ...CartFields
            }
        }
    }
`;

export const SET_PAYMENT_METHOD_BRAINTREE: TypedDocumentNode = gql`
    ${CART_FIELDS}
    mutation SET_PAYMENT_METHOD(
        $cartId: String!
        $paymentMethodCode: String!
        $purchaseOrderNumber: String
        $isActivePaymentTokenEnabler: Boolean!
        $paymentMethodNonce: String!
    ){
        setPaymentMethodOnCart(input: {
            cart_id: $cartId
            payment_method: {
                code: $paymentMethodCode
                purchase_order_number: $purchaseOrderNumber
                braintree: {
                    is_active_payment_token_enabler: $isActivePaymentTokenEnabler
                    payment_method_nonce: $paymentMethodNonce
                }
            }
        }) {
            cart {
                ...CartFields
            }
        }
    }
`;

export const GET_CUSTOMER_TOKENS: TypedDocumentNode = gql`
    query GET_CUSTOMER_TOKENS {
        customerPaymentTokens {
            items {
                public_hash
                payment_method_code
                type
                details
            }
        }
    }
`;
