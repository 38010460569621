import React from 'react';
import { Container } from 'ui/component/container';
import styles from './style.css';

export const TopFooter = () => (
    <div className={styles.topFooter}>
        <div className={styles.topFooterContent}>
            <Container id="social-media" className={styles.socialMedia} />
            <Container id="email-connect" className={styles.socialMedia} />
        </div>
    </div>
);
